import React from 'react';
import _ from 'lodash';

export default class ResponsiveImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLarge: false,
      isSmall: true,
      large: ['device-large', 'device-xlarge'],
      small: ['device-xsmall', 'device-small', 'device-medium'],
      image: null,
      size: null,
    };

    this._getDeviceSize = this.getDeviceSize.bind(this);
  }

  componentDidMount() {
    const smallImage = this.image('responsive-image_mobile', true);
    const largeImage = this.image('responsive-image_desktop');
    this.setState({
      smallImage,
      largeImage,
    }, () => {
      this.getDeviceSize();
      if (smallImage && largeImage) {
        window.addEventListener('resize', this._getDeviceSize);
      }
    });
    
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._getDeviceSize)
  }

  getDeviceSize() {
    const main = document.querySelector('.main');
    if (!main) return;
    const { isLarge, isSmall, large, small } = this.state;
    let size;
    main.classList.forEach(function(c) {
      if (c.indexOf('device') > -1) {
        size = c;
        return;
      }
    });

    const isNowLarge = large.indexOf(size) > -1;
    const isNowSmall = small.indexOf(size) > -1;
    // console.log('isLarge', isLarge, isNowLarge);
    // console.log('isSmall', isSmall, isNowSmall);
    // console.log('size', size)

    if ((isNowLarge !== isLarge) || (isNowSmall !== isSmall) || !this.state.image) {
      this.setState({
        isLarge: isNowLarge,
        isSmall: isNowSmall,
        image: isNowLarge ? this.state.largeImage : this.state.smallImage,
      });
    }

    
  }

  customClass() {
    const custom = _.get(this, 'props.customClass');
    const size = _.get(this, 'props.size');
    let className = '';
    if (custom) className += custom + ' ';
    if (size) className += size + ' '
    return className;
  }

  image(cls, isSmall) {
    const smallImage = this.smallImage();
    const src = isSmall && smallImage ? smallImage : this.largeImage();
    
    // console.log('IMAGE', src, isSmall, smallImage, this.largeImage())
    return this.props.background ? 
      <div className={cls}
        // src={src}
        style={{backgroundImage: `url(${src})`}}
        title={this.altText()} /> :
      <img className={cls}
        src={src}
        alt={this.altText()} />
  }

  largeImage() {
    const key = _.get(this, 'props.featured') ? 'featured_desktop.url' : 'url';
    return this.thumbnailImage() || _.get(this, `props.image.${key}`);
  }

  smallImage() {
    const key = _.get(this, 'props.featured') ? 'featured_mobile' : 'mobile';
    return this.thumbnailImage() || _.get(this, `props.image.${key}.url`);
  }

  altText() {
    return _.get(this, 'props.image.alt');
  }

  thumbnailImage() {
    return _.get(this, 'props.thumbnail');
  }

  render() {
    return (
      <div className={"responsive-image " + this.customClass()}>
        {this.state.image}
      </div>
    )
  }
}