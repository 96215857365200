import * as prismic from '@prismicio/client';
import _ from 'lodash';

import { client } from '../prismic-configuration';

const event = new Event('lazy-load');
const elements = [];
const _listener = _.throttle(scrollListener, 200);
let listening = false;

export function lazyFetch(element) {
	elements.push(element);

	scrollListener();
	if (!listening) {
		document.addEventListener('scroll', _listener);
		listening = true;
	}
}

function scrollListener() {
	if (!elements.length) {
		document.removeEventListener('scroll', _listener);
		listening = false;
	}

	const position = window.scrollY + window.innerHeight;
	elements.forEach((element, index) => {
		const top = element.getBoundingClientRect().top;
		if (top <= position) {
			element.dispatchEvent(event);
			elements.splice(index);
		}
	});
}

export async function getURL(data) {
	const element = _.get(data, 'spans[0]');
	const content = _.get(data, 'text');
	let target = element.data.target ? 'target="' + element.data.target + '" rel="noopener"' : '';
  let linkUrl;
  if (_.get(element, 'data.link_type') === 'Web') {
    linkUrl = prismic.asLink(element.data, this.props.linkResolver);
  } else if (_.get(element, 'data.uid')) {
    let type = _.get(element, 'data.type');
    if (type === 'artist') {
      linkUrl = '/artists/' + _.get(element, 'data.uid');
    } else if (type === 'article') {
      linkUrl = '/news/' + _.get(element, 'data.uid');
    } else if (type === 'blog_post') {
      linkUrl = await getBlogURL(data);
    } else {
      linkUrl = _.get(element, 'data.uid');
    }
  }

  return '<a ' + target + ' href="' + linkUrl + '">' + content + '</a>';
}

async function getBlogURL(data) {
	const uid = _.get(data, 'spans[0].data.uid');
	return client.getByUID('blog_post', uid)
		.then((results) => {
			return `/media/${_.get(results, 'data.provider.uid')}/${_.get(results, 'uid')}`
		});
}
