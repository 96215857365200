import React from 'react';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import * as prismic from '@prismicio/client';
import LazyLoad from 'react-lazy-load';

import { client } from '../prismic-configuration';
import NewsItem from '../components/News-item.js';
import Article from '../components/Article.js';
import { Heading, Heading2 } from '../components/Headings.js';
import { IconTwitter } from '../components/Icons.js';
import globalState from '../global-state.js';
import Metadata from '../helpers/Metadata';
import StickyFilter from '../components/StickyFilter';
import Filters from '../components/Filters';
import Breadcrumbs from '../components/Breadcrumbs';

import '../dist/styles/news.css';

export default class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      types: [],
      filtered: [],
      filters: [],
      currentArticle: null,
      pageNumber: 1,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const params = _.get(this, 'props.params.match');
    const { search } = document.location;
    const filter = new URLSearchParams(search).get('filter');

    if (params) {
      this.setState({
        params: params,
      });
    }

    if (filter) {
      this.setState({
        search,
      });
    }

    this.getContent(filter);
  }

  getContent(filter, page = 1) {
    const self = this;

    client.getByType('news_page')
      .then(data => {
        const res = _.get(data, 'results[0].data');
        if (res && Object.keys(res).length > -1) {
          let types = [];
          let filters = [];
          res.filters.forEach(function(val) {
            const tag = val.filter;
            let obj = {
              name: tag.charAt(0).toUpperCase() + tag.substr(1),
              value: tag
            }
            if (!_.find(types, obj)) types.push(obj);
            if (filters.indexOf(tag) <= -1) filters.push(tag);
          });

          types = [{
            type: 'multi',
            items: _.uniq(filters),
          }];

          self.setState({
            title: _.get(res, 'title[0].text'),
            twitterLink: _.get(res, 'twitter_link.url'),
            types,
            filters,
            metadata: {
              data: { description_metadata: _.get(res, 'description_metadata')},
            },
          });
        }
      })
      .then(() => self.getArticles(filter, page))
      .catch(function(err) {
        console.log(err);
        window.removeEventListener('scroll', self.handleScroll);
        self.setState({ empty: true });
      });
  }

  getArticles(filter, page = 1) {
    const self = this;
    const query = [prismic.filter.at('document.type', 'article')];
    const filters = ['artist'];
    const [filterType, filterValue] = filter ? filter.split(':') : [];
    let getArtist = () => Promise.resolve();

    if (filterType && filterValue && filters.indexOf(filterType) > -1) {
      getArtist = () => client.getByUID(filterType, filterValue);
    }

    getArtist()
      .then((data) => {
        const id = _.get(data, 'id');
        if (id) {
          query.push(prismic.filter.at(`my.article.${filterType}.name`, id));

          const crumbs = [{
            url: '/artists',
            text: 'Artists'
          }, {
            url: `/artists/${_.get(data, 'uid')}`,
            text: _.get(data, 'data.name[0].text'),
          }, {
            text: this.state.title,
          }];

          this.setState({
            back: crumbs,
          });
        }
      })
      .then(() => client.get({
        filters: query,
        orderings: [
          {
            field: 'my.article.date',
            direction: 'desc',
          },
          {
            field: 'document.first_publication_date',
            direction: 'desc',
          }
        ],
        pageSize: 100,
        page,
      }))
      .then(data => {
        const res = _.get(data, 'results');
        if (res && Object.keys(res).length > -1) {
          const currentArticle = self.findArticle(res);
          const { articles, filtered } = self.state;

          self.setState({
            articles: articles.concat(res),
            filtered: filtered.concat(res),
            currentArticle,
            articleIndex: self.articleIndex(res),
            pageNumber: _.get(data, 'page'),
          });

          globalState.set('news', {
            title: self.state.title,
            twitterLink: self.state.twitterLink,
            types: self.state.types,
            filters: self.state.filters,
            metadata: self.state.metadata,
            articles: res,
            filtered: res,
          });

          if (_.get(data, 'page', 0) < _.get(data, 'total_pages')) {
            document.addEventListener('scroll', self.handleScroll);
          } else {
            document.removeEventListener('scroll', self.handleScroll);
          }
        } else {
          document.removeEventListener('scroll', self.handleScroll);
          self.setState({ empty: true });
        }
      })
  }

  setContent(state) {
    this.setState(state);
  }

  articleIndex(articles, url) {
    const currentURL = url || _.get(this, 'props.params.match.params.id');
    return _.findIndex(articles, {uid: currentURL});
  }

  findArticle(articles, url) {
    const currentURL = url || _.get(this, 'props.params.match.params.id');
    return _.find(articles, {uid: currentURL});
  }

  setFilteredItems(items) {
    this.setState({
      filtered: []
    });

    setTimeout(function() {
      this.setState({
        filtered: items,
        empty: items.length <= 0
      });
    }.bind(this), 100);
  }

  setArticle() {
    const { articles, currentArticle } = this.state;
    const currentURL = _.get(this, 'props.params.match.params.id');
    const isSame = _.get(currentArticle, 'uid') === currentURL;
    const index = this.articleIndex(articles, currentURL);
    const articleData = currentArticle && isSame ? currentArticle : this.findArticle(articles, currentURL);

    return { currentArticle: articleData, articleIndex: index };
  }

  handleScroll(event) {
    const bottom = window.innerHeight + window.scrollY;
    const height = document.querySelector('body').offsetHeight;

    if (bottom >= (height - 400)) {
      document.removeEventListener('scroll', this.handleScroll);
      this.getNextBatch();
    }
  }

  getNextBatch() {
    const { search, pageNumber } = this.state;
    const filter = new URLSearchParams(search).get('filter');
    this.getArticles(filter, pageNumber + 1);
  }

  render() {
    const { types, articles, filtered, metadata, twitterLink, title, back, params, empty, search } = this.state;
    const currentURL = _.get(this, 'props.params.match.params.id');
    const { currentArticle, articleIndex } = this.setArticle();

    return (
      <section>
        {metadata && <Metadata title="News" data={metadata} />}
        {twitterLink && <Helmet>
          <script src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
        </Helmet>}

        {title && <Heading text={title} customClass="padding-vertical-medium-l" />}

        {back && <Breadcrumbs pages={back} customClass="padding-vertical-medium-l" />}

        <div className="grid-1 grid-container column">
          {types.length > 0 && 
            <StickyFilter
                filters={types}
                items={articles}
                filteredItems={filtered}
                callback={this.setFilteredItems.bind(this)}>
              {/* <Filters
                filters={types}
                items={articles}
                filteredItems={filtered}
                callback={this.setFilteredItems.bind(this)} /> */}
            </StickyFilter>
          }
        </div>

        <div className="grid-container column-s">
          {filtered && <div className="grid-container wrap grid-70-large break-column-medium-s articles">
            {filtered.length > 0 && filtered.map(function(article, index) {
              return (
                <div className="grid-2-m grid-1" data-tags={article.tags} key={article.id}>
                  <LazyLoad >
                    <NewsItem 
                      data={article}
                      params={params}
                    />
                  </LazyLoad>
                </div>
              )
            }, this)}
            {empty && 
              <div className="empty padding-medium">
                <Heading2 text="0 articles found, try a different filter" />
              </div>
            }
          </div>}

          {twitterLink && <div className="twitter-feed grid-30-large grid-1 padding-vertical-medium-l margin-top-medium">
            <div className="twitter-feed_container">
              <Heading2 customClass="padding-small margin-none"><IconTwitter size="medium" /> @lonelyghostrecs</Heading2>
              <div className="twitter-feed_timeline">
                <a className="twitter-timeline" href={twitterLink}>Tweets by lonelyghostrecs</a>
              </div>
            </div>
          </div>}
        </div>

        {currentURL && !_.isNil(currentArticle) && 
          <Article
            params={params}
            data={currentArticle}
            prev={filtered[articleIndex - 1]}
            next={filtered[articleIndex + 1]}
            currentURL={currentURL}
            search={search || ''} />
        }
      </section>
    );
  }
}