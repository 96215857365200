import React from 'react';
import _ from 'lodash';

import '../../styles/instagram.scss';

export default class Instagram extends React.Component {
	componentDidMount() {
		if (window.instgrm) {
	    window.instgrm.Embeds.process();
		}
	}

  render() {
  	const { data } = this.props;
		let embedded = _.get(data, 'body[0].primary.instagram_link.html');
		if (embedded) {
			embedded = _.replace(embedded, '<script async src="//www.instagram.com/embed.js"></script>', '')
		}

    return (
    	<div className="blog-widget_instagram" dangerouslySetInnerHTML={{__html: embedded}} />
    )
  }
}
