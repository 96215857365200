import React from 'react';
import * as prismic from '@prismicio/client';
import _, { omit } from 'lodash';

import Card from '../components/card';
import { Heading, Heading2 } from '../components/Headings.js';
import Metadata from '../helpers/Metadata';
import SecondaryNav from '../components/SecondaryNav';
import Pagination from '../components/Pagination';
import Breadcrumbs from '../components/Breadcrumbs';
import Filter from '../components/Filter';
import Filters from '../components/Filters';

import { getPageData, getItemsData } from '../data/merch';
import { getArtistData } from '../data/artists';

const PAGE_TYPE = 'merch_item';

export default class MerchList extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.setup(props);
  }

  componentDidMount() {
    this.getContent();
  }

  componentDidUpdate(prevProps) {
    const uid = _.get(this, 'props.params.match.params.id');
    const prev = _.get(prevProps, 'params.match.params.id');

    if (uid !== prev) {
      this.setState(this.setup(this.props));
      this.getContent();
    }
  }

  setup(props) {
    const params = new URLSearchParams(document.location.search);
    return {
      artist: _.get(props, 'params.match.params.artist') ? true : false,
      id: _.get(props, 'params.match.params.id'),
      items: [],
      currentPage: parseInt(params.get('page')) || 1,
      totalResults: 0,
      filters: [],
      title: null,
    };
  }

  async getContent() {
    if (this.state.artist) {
      await this.getArtistData();
    }

    const { links } = await getPageData();
    const currentUrl = _.find(links, { url: this.state.id });
    this.setState({
      title: this.state.title || _.get(currentUrl, 'text'),
      links: links,
    });

    this.getItems();
  }

  async getArtistData() {
    const data = await getArtistData(this.state.id);
    if (data) {
      this.setState({
        title: _.get(data, 'name'),
        artistData: data,
      });
    }
  }

  async getItems(filters = [], sorts = []) {
    const modifiedFilters = [
      this.state.artist
        ? prismic.filter.at(`my.${PAGE_TYPE}.artist`, this.state.artistData.id)
        : prismic.filter.at('document.tags', [decodeURIComponent(this.state.id)]),
      ...filters,
    ];
    const modifiedSorts = sorts.length > 0
      ? sorts
      : [
        {
          field: 'document.first_publication_date',
          direction: 'desc',
        },
      ];
    const { items, totalPages, totalResults } = await getItemsData(this.state.artistData, {
      filters: modifiedFilters,
      sorts: modifiedSorts,
      pageSize: 24,
      page: this.state.currentPage,
    });

    this.setState({
      items,
      filtered: items,
      totalPages,
      totalResults: totalResults || 0,
      filters: this.setFilters(items),
    });
  }

  setFilters(items) {
    const artists = [];
    const types = [];

    items.forEach((item) => {
      if (item.artist && artists.findIndex((artist) => artist.name === item.artist) === -1) {
        artists.push({
          name: item.artist,
          value: item.artistId,
        });
      }
      if (item.type && types.indexOf(item.type) === -1) types.push(item.type);
    });

    const filters = [
      {
        type: 'unique',
        key: 'artist',
        default: 'Artists',
        items: artists,
      },
      {
        type: 'multiselect',
        key: 'Type',
        default: 'Type',
        items: types,
      },
      {
        type: 'sort',
        key: 'Sort',
        default: 'Sort',
        items: [
          {
            name: 'Latest',
            value: 'latest'
          },
          {
            name: 'Price low to high',
            value: 'price'
          }
        ]
      }
    ];

    filters.forEach((filter, index) => {
      if (filter.items.length <= 1) {
        filters.splice(index, 1);
      }
    });

    return filters;
  }

  pages() {
    const href = _.split(_.get(window, 'location.pathname', ''), '/');
    const url = `/${href[1]}/${href[2]}`;
    const text = _.get(this, 'state.title');

    return [
      {
        url: '/' + href[1],
        text: _.capitalize(href[1]),
      },
      {
        url,
        text,
      }
    ];
  }

  setFilteredItems(selectedFilters, selectedSorts) {
    const filters = [];

    const types = selectedFilters.filter((filter) => filter.type.toLowerCase() === 'type');
    const omitedTypes = types.filter((t) => !t.selected);

    if (omitedTypes.length > 0) {
      filters.push(prismic.filter.not('document.tags', omitedTypes.map((s) => s.name)));
    }
    
    selectedFilters.forEach((filter) => {
      if (filter.value) {
        filters.push(prismic.filter.at(`my.${PAGE_TYPE}.${filter.type}`, filter.value));
      }
    });

    const sorts = selectedSorts.map((sort) => {
      if (sort.value === 'latest') {
        return {
          field: 'document.first_publication_date',
          direction: 'desc',
        };
      }

      return {
        field: `my.${PAGE_TYPE}.${sort.value}`,
      };
    });

    return this.getItems(filters, sorts);
  }

  render() {
    const { metadata, title, links, subtitle, totalPages, currentPage, filters, filtered, items, totalResults } = this.state;

    return (
      <section>
        {metadata && <Metadata title={title} data={metadata} />}
        {links && 
          <SecondaryNav links={links}>
            {filters.length > 0 && <Filter>
              <Filters
                filters={filters}
                callback={this.setFilteredItems.bind(this)} />
            </Filter>}
          </SecondaryNav>
        }
        <div className='padding-vertical-medium-l'>
          <Breadcrumbs pages={this.pages()} />
        </div>
        {title && <Heading text={title} customClass="padding-vertical-medium-l margin-bottom-medium" />}
        
        {totalResults > 0 &&
          <>
            {totalResults > 1
              ? <div className='padding-vertical-medium-l body-small'>{totalResults} Products</div>
              : <div className='padding-vertical-medium-l body-small'>{totalResults} Product</div>
            }
            {items.length && 
              <div className="artists grid-container wrap padding-top-small margin-bottom-medium break-column-s">
                {items.map((item, index) => (
                  <Card key={item.title} title={item.title} subtitle={item.artist} subtext={item.price} image={item.image} link={item.link} />
                ), this)}
              </div>
            }

            {subtitle && <Heading2 text={subtitle} customClass="padding-vertical-medium-l" />}

            {totalPages > 0 && <Pagination currentPage={currentPage} totalPages={totalPages} />}
          </>
        }
        {totalResults === 0 && <Heading2 text="No results" customClass='padding-vertical-medium-l' />}
      </section>
    );
  }
}