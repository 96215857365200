import React from 'react';
import _ from 'lodash';

import globalState from '../../global-state.js';
import { client } from '../../prismic-configuration';
// import Metadata from '../../helpers/Metadata';
import { Heading, Heading2 } from '../Headings';
import RichText from '../rich-text.js';
import ResponsiveImage from '../responsive-image.js';

function snippet() {
  const href = _.get(window, 'location.pathname');
  const baseURL = _.split(href, '/')[1];
  const url = `/${baseURL}/${this.state.url}`;
  return (
    <div className="margin-bottom-medium padding-vertical-medium-l">
      {this.state.title && <a href={url}>
        <Heading2 text={this.state.title} />
      </a>}
      {this.state.body && <div className="char-limit">
        <RichText text={this.state.body} />
      </div>}
    </div>
  )
}

function fullSection() {
  const { logo, title, body } = this.state;
  const url = _.get(logo, 'url');

  return (
    <div className="padding-vertical-medium-l margin-bottom-small">
      <div className="center-vertical margin-bottom-xsmall">
        {url && <div className="blog-provider_logo">
          <ResponsiveImage image={logo} />
        </div>}
        {title && <Heading text={title} customClass="margin-none" />}
      </div>
      {body && <div className="char-limit">
        <RichText text={body} />
      </div>}
    </div>
  )
}

export default class BlogProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: null,
      sections: [],
      logo: null,
      body: null,
    };
  }

  componentDidUpdate(prevProps) {
    const uid = _.get(this, 'props.data.id');
    const prev = _.get(prevProps, 'data.id');
    if (uid !== prev) {
      this.getContent(uid);
      this.setState({
        title: null,
        sections: [],
        logo: null,
        body: null,
      });
    }
  }

  componentDidMount() {
    const id = _.get(this, 'props.data.id');
    const has = globalState.get(`blog_provider:${id}`);
    if (has) {
      this.setContent(has);
    } else if (id) {
      this.getContent();
    }
  }

  getContent() {
    const id = _.get(this, 'props.data.id');
    const self = this;
  	client.getByID(id)
      .then(data => {
        const body = _.get(data, 'data');

        if (body) {
          const obj = {
            id: _.get(data, 'id'),
            url: _.get(data, 'uid'),
            title: _.get(body, 'provider_name'),
            body: _.get(body, 'about_text'),
            logo: _.get(body, 'logo'),
          }

          self.setContent(obj);
        }
      });
  }

  setContent(state) {
    this.setState(state)
  }

  render() {
    return this.props.type === 'snippet' ? snippet.call(this) : fullSection.call(this);
  }
}
