import React from 'react';
import _ from 'lodash';

import { Heading, Heading2 } from '../Headings.js';
import RichText from '../rich-text.js';
import Metadata from '../../helpers/Metadata';
import { IconTwitter, IconFacebook, IconReddit } from '../Icons.js';
import BlogLazyWidget from './BlogLazyWidget';
import BlogItem from './BlogItem';

function Facebook() {
	const hostName = window.location.host;
	const href = window.location.pathname;
	const url = `http://www.facebook.com/share.php?u=${hostName}${href}`;
	return (
		<div className="social_share">
			<a href={url} target="blank">
				<IconFacebook size="medium"/>
			</a>
		</div>
	)
}

function Twitter() {
	return (
		<div className="social_share">
			<a href="https://twitter.com/share?ref_src=twsrc%5Etfw"
				className="twitter-share-button"
				data-show-count="false"
				target="blank">
				<IconTwitter size="medium"/>
			</a>
		</div>
	)
}

function Reddit(data) {
	const hostName = window.location.host;
	const href = window.location.pathname;
	const title = _.get(data, 'title', '');
	const text = _.get(data, 'description[0].text', '');
	return (
		<div className="social_share">
			<a href={`https://www.reddit.com/submit?url=${hostName}${href}&title=${title}&text=${text}`}
				className="twitter-share-button"
				data-show-count="false"
				target="blank">
				<IconReddit size="medium"/>
			</a>
		</div>
	)
}

export default class BlogFull extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
    	title: null,
    	description: null,
    	author: null,
    	date: null,
    	body: [],
    	socialShare: [],
    	width: 'auto',
    };
    this.container = React.createRef();
    this._setWidth = this.setWidth.bind(this);
  }

  setWidth() {
    const container = _.get(this, 'container.current');
    const width = container ? container.getBoundingClientRect().width : 'auto';
    this.setState({width});
    const videos = document.querySelectorAll('[data-oembed-provider="YouTube"]');
    if (videos.length > 0) {
    	_.forEach(videos, (video) => {
    		const iframe = video.querySelector('iframe');
    		if (iframe) {
    			const originalWidth = _.get(iframe, 'width');
					const originalHeight = _.get(iframe, 'height');
					let height = (originalHeight / originalWidth) * width;
					height = _.isNaN(height) ? originalHeight : height;
					video.style.height = height + 'px';
    		}
    	});
    }
  }

  componentDidMount() {
    this.setWidth();
    window.addEventListener('resize', this._setWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._setWidth);
  }

	render() {
		const { title, description, author, date, body, socialShare, relatedBlogs, metadata } = this.props.data;
		return (
			<div ref={this.container}>
				{metadata && <Metadata data={metadata} title={title} />}
				<div className="blog padding-vertical-medium-l">
					<div className="blog_head">
						{title && <Heading text={title} />}
						{description && <p className="body-medium"><RichText text={description} /></p>}
						{author && <p>{author}</p>}
						{date && <p className="body-small">{date}</p>}
					</div>
					{body.length > 0 && <div className="blog_body">
						{body.map(function(item, index) {
							const slice = _.get(item, 'slice_type');
							const data = _.get(item, 'primary') && _.keys(item.primary).length ?
								item.primary[_.keys(item.primary)[0]] :
								_.get(item, 'items.length');

			        return <BlogLazyWidget slice={slice} isWidget={false} data={data} additional={item} key={index} />
							
			      }, this)}
					</div>}
					{socialShare.length > 0 && <div className="blog_social">
						{socialShare.map((social, index) => {
							if (social.social_media_provider === 'twitter') {
								return <Twitter key={index}/>
							} else if (social.social_media_provider === 'facebook') {
								return <Facebook key={index} />
							} else if (social.social_media_provider === 'reddit') {
								return <Reddit key={index} title={title} description={description} />
							}
							return <div />
						}, this)}
					</div>}
				</div>
				{relatedBlogs.length > 0 &&
					<div className="padding-vertical-small-l margin-top-xlarge">
						<Heading text="Related" customClass="padding-vertical-small-l" />
						<div className="grid-container">
							{relatedBlogs.map((blog) => {
								return <div className="grid-4-m padding-vertical-small padding-bottom-medium" key={blog.related_blog.uid}>
									<BlogItem uid={blog.related_blog.uid} type="widget" />
								</div>
							}, this)}
						</div>
					</div>
				}
				{this.state.error && <section>
          <Heading2 text="An error has occurred, please try again later" />
        </section>}
			</div>
		)
	}
}
