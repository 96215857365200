import React from 'react';
import _ from 'lodash';
import '../styles/pagination.scss';

export default function Pagination(props) {
  return (
    <div className='pagination'>
      <h3>
        {_.times(props.totalPages, (num) => (
          num + 1 === props.currentPage
            ? <span key={num} className='active page'>{num + 1}</span>
            : <a key={num} href={`?page=` + (num + 1)} className='page'>{num + 1}</a>
        ))}
      </h3>
    </div>
  )
};