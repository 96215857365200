import React from 'react';
import _ from 'lodash';

import { client } from '../../prismic-configuration';
import { lazyFetch } from '../../helpers/utility';
import { Heading } from '../Headings.js';
import Item from './item';

import '../../styles/featured-item.scss';

export default class Features extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      features: [],
      index: 0,
      limit: 5,
      items: [],
    };

    this._setScroller = this.setScroller.bind(this);
    this._onScroll = this.onScroll.bind(this);
    this.container = React.createRef();
    this._getContent = this.getContent.bind(this);
  }

  componentDidMount() {
    this.container.current.addEventListener('lazy-load', this._getContent);
    lazyFetch(this.container.current)

    window.addEventListener('resize', this._setScroller);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._setScroller);
    this.container.current.removeEventListener('lazy-load', this._getContent);
  }

  componentDidUpdate(prevProps) {
    const data = this.props.data;
    const has = _.get(this, 'state.features.length') > 0;
    if (data && data !== prevProps.data && !has) {
      this.getContent();
    }
  }

  getContent() {
    this.setState({loaded: true});
    this.container.current.removeEventListener('lazy-load', this._getContent);
    const data = this.props.data;
    if (data) {
      const queries = _.map(data, function(value) {
        return _.get(value, 'release.id');
      });

      this.setState({ queries, total: data.length });

      const initialElements = [].concat(queries.splice(0, this.state.limit), queries.splice(queries.length - 2, queries.length));
      Promise.all(_.map(initialElements, (loc) => client.getByID(loc)))
        .then(this.processData.bind(this))
        .then(this.setScroller.bind(this))
        .catch(function(err) {
          console.log('Releases error', err);
        });
    }
  }

  getRest() {
    const { queries, features, limit } = this.state;
    const self = this;
    const promises = _.map(queries, (loc) => {
      return client.getByID(loc)
    });
    return Promise.all(promises)
      .then((data) => {
        const q = [].concat(features);
        const start = q.splice(0, limit);
        const joined = start.concat(data, q);
        return self.processData(joined);
      })
      .catch(function(err) {
        console.log('Releases error', err);
      });
  }

  processData(data) {
    const self = this;
    return new Promise((resolve, reject) => {
      if (data && data.length > 0) {
        const normalized = _(data)
          .map((val) => {
            return _.get(val, 'results[0]') || val;
          })
          .uniqBy('id')
          .value();

        self.setState({
          features: normalized,
        }, () => {
          self.props.childMounted('Features');
          resolve();
        });
      }
    })
  }

  setScroller() {
    const classes = document.querySelector('.main').classList;
    const small = ['device-small', 'device-xsmall', 'device-medium'];
    const limit = _.intersection(small, classes).length > 0 ? 3 : 5;
    const area = window.innerWidth / limit;
    const multiplier = limit === 5 ? 4 : 6;
    const width = window.innerWidth + (area * multiplier);
    const {features, total} = this.state;

    if (limit !== this.state.limit || _.get(this, 'state.items.length') <= 0) {
      this.setState({
        items: features.slice(features.length - 2).concat(features.slice(0, limit)),
        index: total - 2,
        limit: limit,
      });
    }

    this.setState({
      width: total > limit ? width : 100,
      left: limit === 5 ? -(area * 2): -(area * 3),
    })

    this.scrollToMiddle();
  }

  scrollToMiddle() {
    const {limit} = this.state;
    if (limit === 3) {
      const scroller = document.querySelector('.scroller');
      const middle = (scroller.scrollWidth / 2) - (window.innerWidth / 2);
      scroller.scrollTo(middle, 0);
    }
  }

  setContent(state) {
    const self = this;
    this.setState({
      features: state.features,
      queries: state.queries,
      total: state.total,
      loaded: true,
    });
    setTimeout(() => {
      self.setScroller();
      self.props.childMounted('Features');
    }, 200);
  }

  setList(direction, i) {
    let {index, features, limit, total} = this.state;
    const dataCall = features.length !== total ? this.getRest.bind(this) : Promise.resolve.bind(Promise);
    const buffer = 2;
    const totalLength = limit + buffer;
    const self = this;

    dataCall()
      .then(() => {
        features = self.state.features;
        if (!_.isNil(i)) {
          index = i;
        } else if (direction === 1) {
          index = index + 1 <= features.length - 1 ? index + 1 : 0;
        } else {
          index = index - 1 >= 0 ? index - 1 : features.length - 1;
        }

        let sliced = features.slice(index, index + totalLength);

        if (sliced.length < totalLength) {
          const last = totalLength - sliced.length;
          const start = 0;
          const additional = features.slice(start, last);
          sliced = sliced.concat(additional);
        }

        self.setState({
          items: sliced,
          index,
          transform: 0,
        });
      });
  }

  startScroll(event) {
    this.setState({
      startPosition: event.touches[0].clientX,
    })
    document.addEventListener('touchmove', this._onScroll);
  }

  onScroll(event) {
    const {startPosition} = this.state;
    const newPosition = _.get(event, 'changedTouches[0].clientX');
    const transform = newPosition - startPosition;

    this.setState({
      transform,
    });
  }

  endScroll(event) {
    document.removeEventListener('touchmove', this._onScroll);
    const target = event.currentTarget;
    target.classList.add('moving')
    const children = target.querySelectorAll('.featured-item');
    const inFocus = _.find(children, (c) => {
      const p = c.getBoundingClientRect();
      return (p.left + (p.width / 4)) >= 0;
    });
    const id = inFocus.getAttribute('data-id');
    const index = _.findIndex(this.state.features, {id});
    const currentId = target.querySelector('.featured-item_active').getAttribute('data-id');
    const currentIndex = _.findIndex(this.state.features, {id: currentId});

    let direction = index > currentIndex ? 1 : -1;

    if (index === this.state.features.length - 1 && currentIndex === 0) {
      direction = -1;
    }

    if (index === 0 && currentIndex === this.state.features.length - 1) {
      direction = 1;
    }

    const left = (window.innerWidth / 2) - (inFocus.getBoundingClientRect().width / 2);
    const elemLeft = inFocus.getBoundingClientRect().left - left;
    const middle = this.state.transform - elemLeft;

    this.setState({
      transform: middle,
    })

    const self = this;
    setTimeout(() => {
      target.classList.remove('moving');

      if (index === currentIndex) {
        this.setState({
          transform: 0,
        });
      } else {
        self.setList(direction);
      }
    }, 210);
  }

  currentPage() {
    const {index, features} = this.state;
    const length = features.length - 1;
    const start = index + 2 > length ? (index + 1) - length : index + 2;

    return start + 1 && (length > 0) ? `${start + 1} / ${length + 1}` : '';
  }

  render() {
    if (this.props.data.length > 0) {
     return (
        <section className={this.state.loaded ? "loaded feature-container margin-bottom-xlarge" : "feature-container margin-bottom-xlarge"} ref={this.container}>
          {this.state.items.length > 0 && <Heading type="heading" text={this.props.title} />}
          
          {this.state.items.length > 0 &&
            <div className="scroller" onTouchEnd={(event) => this.endScroll(event)} onTouchStart={(event) => this.startScroll(event)}>
              <button className="scroller_button left" onClick={() => this.setList(-1)} />
              <div className="feature-container_items grid-container" style={{width: this.state.width + 'px', left: this.state.left + 'px', transform: 'translateX(' +this.state.transform + 'px)'}}>
                {this.state.items && this.state.items.map(function(feature, index) {
                  return <Item data={feature} key={feature.id} isActive={index === Math.ceil((this.state.items.length - 1) / 2)} />
                }.bind(this))}
              </div>
              <button className="scroller_button right" onClick={() => this.setList(1)} />
            </div>}
          {this.state.features && <div className="scroller_indicators body-small">
            {this.currentPage()}
          </div>}
          
        </section>
      ); 
    } else {
      return null;
    }
  }
}