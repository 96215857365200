import React from 'react';
import _ from 'lodash';
import ResponsiveImage from './responsive-image.js';
import { client } from '../prismic-configuration';
import '../styles/employee.scss';

export default class Employee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      image: null,
      title: null
    };
    this.getInfo();
  }

  getInfo(prevProps) {
    const self = this;
    const id = _.get(this, 'props.info.id');
    client.getByID(id)
      .then(data => {
        const res = _.get(data, 'data');
        if (res && Object.keys(res).length > -1) {
          self.setState({
            name: _.get(res, 'name[0].text'),
            image: _.get(res, 'image'),
            title: _.get(res, 'title[0].text'),
          });
        }
      })
      .catch(function(err) {
        console.log(err);
      });
  }

  render() {
    return (
      <div className="employee grid-4-l grid-2">
        <div className="padding-right-large-l padding-vertical-xsmall-s padding-bottom-medium">
          {this.state.image && 
            <div className="employee_image">
              <ResponsiveImage image={this.state.image} />
            </div>
          }
          <div className="employee_content">
            {this.state.name && <h3>{this.state.name}</h3>}
            {this.state.title && <p>{this.state.title}</p>}
          </div>
        </div>
      </div>
    );
  }
}
