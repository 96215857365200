import React from 'react';
import { NavLink } from 'react-router-dom';

import { IconTwitter, IconInstagram, IconHamburger, IconClose, IconBandcamp } from './Icons.js';
import logo from '../assets/images/ghost.png';
import '../styles/navigation.scss';

export default class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      float: false,
      show: false,
      hidden: ' hidden',
    };
    this.float = this.float.bind(this);
    this.show = this.show.bind(this);
    this.divClass = this.divClass.bind(this);
    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    const self = this;
    window.addEventListener('scroll', function() {
      self.setState({
        float: self.float(),
        show: self.show(),
        hidden: ' hidden'
      });
    });
  }

  float() {
    return window.location.pathname === '/' ? true : false;
  }

  show() {
    const atHome = this.float();
    if (!atHome) return true;
    const hero = document.querySelector('.hero');
    const nav = document.querySelector('.navigation');

    const heroHeight = hero ? hero.offsetHeight : 0;
    const navHeight = nav ? nav.offsetHeight : 0;
    const passedHero = window.scrollY > Math.max(heroHeight - navHeight, 0);
    return passedHero;
  }

  divClass() {
    let c = 'navigation';
    c += this.show() ? ' navigation_show' : '';
    c += this.float() ? ' navigation_float' : '';

    return c;
  }

  hide(hide) {
    this.setState({
      hidden: hide ? ' hidden' : ''
    });
  }

  render() {
    return (
      <nav className={this.divClass() + this.state.hidden}>
        <div className="navigation-container">
          <NavLink to="/" className="logo-container">
            <img src={logo} alt="Lonely Ghost Records" className="navigation-logo" />
          </NavLink>
          <div className="navigation-overlay" onClick={this.hide.bind(this, true)}></div>
          <div className="navigation-links">
            <div className="navigation-button_close" onClick={this.hide.bind(this, true)}>
              <IconClose size="medium" />
            </div>
            <NavLink to="/about" className="navigation-link" onClick={this.hide.bind(this, true)}>About</NavLink>
            <NavLink to="/news" className="navigation-link" onClick={this.hide.bind(this, true)}>News</NavLink>
            <NavLink to="/artists" className="navigation-link" onClick={this.hide.bind(this, true)}>Artists</NavLink>
            <NavLink to="/media" className="navigation-link" onClick={this.hide.bind(this, true)}>Media</NavLink>
            <NavLink to="/merch" className="navigation-link" onClick={this.hide.bind(this, true)}>Merch</NavLink>
            {/* <a className="navigation-link" href="http://lonelyghostrecords.limitedrun.com/" target="blank">Merch</a> */}
            <a href="https://www.twitter.com/lonelyghostrecs/" target="blank" className="navigation-link_icon">
              <IconTwitter size="medium"/>
            </a>
            <a href="https://lonelyghostrecs.bandcamp.com/" target="blank" className="navigation-link_icon">
              <IconBandcamp size="medium"/>
            </a>
            <a href="https://www.instagram.com/lonelyghostrecs/" target="blank" className="navigation-link_icon">
              <IconInstagram size="medium"/>
            </a>
          </div>
          <div className="navigation-button" onClick={this.hide.bind(this, false)}>
            <IconHamburger />
          </div>
        </div>
      </nav>
    );
  }
}