import React from 'react';
import _ from 'lodash';
import * as prismic from '@prismicio/client';


import { client } from '../../prismic-configuration';
import globalState from '../../global-state.js';
import HeroItem from './HeroItem';

import '../../styles/hero.scss';

export default class Hero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heros: [],
    };
  }

  componentDidMount() {
    const has = globalState.get('heros');
    if (has) {
      this.setContent(has);
    } else {
      this.getContent();
    }
  }

  componentDidUpdate(prevProps) {
    const data = this.props.data;
    const has = _.get(this, 'state.heros.length') > 0;
    if (data && data !== prevProps.data && !has) {
      this.getContent();
    }
  }

  getContent() {
    const self = this;
    if (this.props.data) {
      const sliced = this.props.data.slice(0, 5);
      const queries = _.map(sliced, function(value) {
        const id = _.get(value, 'hero_item.id');
        return client.get({
          filters: [
            prismic.filter.at('document.id', id)
          ]
        });
      });

      Promise.all(queries)
        .then(function(data) {
          if (data && data.length > 0) {
            const normalized = _.map(data, function(val) {
              return _.get(val, 'results[0]');
            })
            const obj = {heros: normalized};
            self.setContent(obj);
            globalState.set('heros', obj);
            self.props.childMounted('Hero');
          }
        })
        .catch(function(err) {
          console.log('error', err);
        });
    }
  }

  setContent(state) {
    this.setState(state);
  }

  render() {
    return (
      <div className="hero">
        {this.state.heros && this.state.heros.map(function(hero, index) {
          return <HeroItem data={hero} key={index} />
        }, this)}
      </div>
    );
  }
}
