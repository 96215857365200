import React from 'react';
import _ from 'lodash';
import ResponseImage from '../responsive-image.js';

import Playlist from '../Widgets/Playlist';
import Blog from '../Widgets/Blog';
import Youtube from '../Widgets/Youtube';
import Instagram from '../Widgets/Instagram';

// const widgets = {
// 	'feature_image': () => import('../responsive-image.js'),
// 	'playlist': () => import('../Widgets/Playlist'),
// 	'blog': () => import('../Widgets/Blog'),
// 	'blog_body': () => import('../Widgets/Blog'),
// 	'video': () => import('../Widgets/Youtube'),
// 	'youtube': () => import('../Widgets/Youtube'),
// 	'soundcloud': () => import('../Widgets/Playlist'),
// 	'spotify': () => import('../Widgets/Playlist'),
// 	'instagram': () => import('../Widgets/Instagram'),
// }

const widgets = {
	'feature_image': ResponseImage,
	'playlist': Playlist,
	'blog': Blog,
	'blog_body': Blog,
	'video': Youtube,
	'youtube': Youtube,
	'soundcloud': Playlist,
	'spotify': Playlist,
	'instagram': Instagram,
}

export default class BlogLazyWidget extends React.Component {
	render() {
		const { slice, isWidget, data, additional } = this.props;
		const Widget = _.get(widgets, slice);
		// const Widget = React.lazy(mod);
		if (slice === 'feature_image') {
			const size = _.get(additional, 'primary.size');
			// return (
			// 	<React.Suspense fallback={<div />}>
			// 		<Widget image={{url: 'https://undark.org/wp-content/uploads/2020/02/GettyImages-1199242002-1-scaled.jpg'}} size={size} customClass="blog_featured-image" />
			// 	</React.Suspense>
			// )

			return (
				<ResponseImage image={data} size={size} customClass="blog_featured-image" />
			)
		}

		// return (
		// 	<React.Suspense fallback={<div />}>
		// 		<Widget data={data} content={state} type={isWidget ? 'widget' : 'full'} />
		// 	</React.Suspense>
		// )

		if (Widget) {
			return (
				<Widget data={data} type={isWidget ? 'widget' : 'full'} />
			)
		} else {
			return <div />
		}
	}
}