import React from 'react';
import _ from 'lodash';

import { client } from '../prismic-configuration';
import globalState from '../global-state.js';
import SecondaryNav from '../components/SecondaryNav';
import Breadcrumbs from '../components/Breadcrumbs';
import BlogItem from '../components/Blog/BlogItem';

export default class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
    };
  }

  componentDidMount() {
    const provider = _.get(this, 'props.params.match.params.provider');
    const id = _.get(this, 'props.params.match.params.id');
    const has = globalState.get(`blog:${id}`);
    if (has) {
      this.setContent(has);
    } else {
      this.getContent(provider);
    }
  }

  getContent(provider) {
    const self = this;
    const uid = _.get(this, 'props.params.match.params.id');
    this.setState({ uid });
    client.getByUID('blog_provider', provider)
      .then(data => {
        self.setContent({
          provider: data,
        });
      });
  }

  setContent(state) {
    this.setState(state);
  }

  pages() {
    const href = _.get(window, 'location.pathname');
    const baseURL = _.split(href, '/')[1];
    const url = `/${baseURL}/${_.get(this, 'state.provider.uid')}`;
    const text = _.get(this, 'state.provider.data.provider_name');

    return [
      {
        url: '/' + baseURL,
        text: _.capitalize(baseURL),
      },
      {
        url,
        text,
      }
    ];
  }

  render() {
    const { uid } = this.state;
    return (
    	<section>
        <SecondaryNav />
        <Breadcrumbs pages={this.pages()} customClass="padding-vertical-medium-l" />
        {uid && <BlogItem uid={uid} />} 
      </section>
  	)
  }
}
