import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Home from './pages/Home';
import About from './pages/About';
import Artist from './pages/Artist';
import Artists from './pages/Artists';
import Merch from './pages/Merch';
import MerchList from './pages/MerchList';
import News from './pages/News';
import BlogLanding from './pages/BlogLanding';
import BlogProvider from './pages/BlogProvider';
import Blog from './pages/Blog';
import Preview from './pages/Preview';
// import NotFound from './NotFound';
import Loader from './components/Loader.js';

import Navigation from './components/Navigation.js';
import Footer from './components/Footer.js';
import { default as metadata } from './metadata_defaults.js';

import './dist/styles/base.css';

import { apiEndpoint } from './prismic-configuration'
const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(apiEndpoint)
const repoName = repoNameArray[1]

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true
    };
    this.setLoaderState = this.setLoaderState.bind(this);
  }

  componentDidMount() {
    const self = this;
    this.setState({
      device: this.checkDevice()
    });
    window.addEventListener('resize', function() {
      self.setState({
        device: self.checkDevice()
      });
    });
  }

  setLoaderState(state) {
    state = state ? state : false;
    this.setState({
      loader: state
    });
  }

  checkDevice() {
    const width = window.innerWidth;
    const devices = {
      0: 'xsmall',
      400: 'small',
      700: 'medium',
      900: 'large',
      1200: 'xlarge'
    }

    const size = Object.keys(devices).filter(function(key, index, arr) {
      const next = arr[index+1];
      if (width >= key && (!next || width < next)) {
        return devices[key];
      }
      return ''
    });

    return 'main device-' + devices[size[0]];
  }

  render() {
    return (
      <Router>
        <div className={this.checkDevice()}>
          <Helmet>
            <meta name="description" content={metadata.desc} />
          </Helmet>
          {process.env.NODE_ENV === 'development' && <Helmet>
            <script async defer src={`//static.cdn.prismic.io/prismic.js?repo=${repoName}&new=true`} />
          </Helmet>}
          
          <Navigation />
          <div className="content">
            <Switch>
              <Route exact path='/preview' component={Preview} />
              <Route exact path="/" render={()=><Home api={this.props.prismicCtx} setLoaderState={this.setLoaderState}/>} />
              <Route exact path="/about" render={()=><About api={this.props.prismicCtx} />} />
              <Route path="/artists/:id" render={(props)=><Artist api={this.props.prismicCtx} params={props} />} />
              <Route path="/artists" render={(props)=><Artists api={this.props.prismicCtx} params={props} />} />
              <Route exact path="/merch" render={()=><Merch api={this.props.prismicCtx} />} />
              <Route exact path="/merch/:id" render={(props)=><MerchList api={this.props.prismicCtx} params={props} />} />
              <Route path="/merch/:artist/:id" render={(props)=><MerchList api={this.props.prismicCtx} params={props} />} />
              <Route exact path="/news/:id" render={(props)=><News api={this.props.prismicCtx} params={props} />} />
              <Route exact path="/news" render={(props)=><News api={this.props.prismicCtx} params={props} />} />
              <Route exact path="/media" render={(props)=><BlogLanding api={this.props.prismicCtx} params={props} />} />
              <Route exact path="/media/:id" render={(props)=><BlogProvider api={this.props.prismicCtx} params={props} />} />
              <Route path="/media/:provider/:id" render={(props)=><Blog api={this.props.prismicCtx} params={props} />} />
              <Redirect to="/" />
            </Switch>
          </div>
          <Footer />
          <Loader show={this.state.loader} />
        </div>
      </Router>
    )
  }
}
