import React from 'react';
import _ from 'lodash';

import ResponsiveImage from '../responsive-image.js';
import { Heading3 } from '../Headings.js';

import '../../styles/video.scss';

function Full(props) {
	const { data, width } = props;
	const embedded = _.get(data, 'html');
	const originalWidth = _.get(data, 'width');
	const originalHeight = _.get(data, 'height');
	let height = (originalHeight / originalWidth) * width;
	height = _.isNaN(height) ? originalHeight : height;
  return (
  	<div className="youtube" style={{height}} dangerouslySetInnerHTML={{__html: embedded}} />
  )
}

function Widget(props) {
	const { data } = props;
	const additional = _.get(data, 'primary');
	const image = _.get(data, 'image') || _.get(additional, 'link.thumbnail_url');
	const title = _.get(data, 'title') || _.get(additional, 'link.title');
	const date = _.get(data, 'date');
	const url = _.get(data, 'url');
	const type = _.get(data, 'type');
  const isFeatured = _.get(data, 'featured', false);

	return (
  	<div className="blog-widget_video">
			{!isFeatured &&<a href={url}>
				{image && <div className="blog-widget_image">
					{_.isObject(image) && <ResponsiveImage image={image} />}
					{_.isString(image) && <img src={image} alt={title} />}
				</div>}
				{type && <p className="tag">{type}</p>}
				{title && <Heading3 text={title} />}
				{date && <p className="body-small">{date}</p>}
			</a>}
      {isFeatured && Full({ data: additional.link, width: props.width, height: props.height })}
		</div>
  )
}

export default class Youtube extends React.Component {
	constructor(props) {
    super(props);
    this.container = React.createRef();
    this.state = {
    	width: 'auto',
    };
    this._setWidth = this.setWidth.bind(this);
  }

  componentDidMount() {
  	this.setWidth();
  	window.addEventListener('resize', this._setWidth);
  }

  componentWillUnmount() {
  	window.removeEventListener('resize', this._setWidth);
  }

  setWidth() {
  	const container = _.get(this, 'container.current');
  	const width = container ? container.getBoundingClientRect().width : 'auto';
  	this.setState({width});
  }

  render() {
  	return (
	  	<div ref={this.container}>
	  		{this.props.type === 'widget' && <Widget data={this.props.data} width={this.state.width} />}
	  		{this.props.type !== 'widget' && <Full data={this.props.data} width={this.state.width} />}
	  	</div>
  	)
  }
}
