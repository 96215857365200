let saved = {};

function get(key) {
  return saved[key];
}

function set(key, value) {
  saved[key] = value;
}

export default {
  get,
  set
}