import React from 'react';
import _ from 'lodash';

import Employee from '../components/employee.js';
import { Heading, Heading2 } from '../components/Headings.js';
import ContactForm from '../components/contact-form.js';
import RichText from '../components/rich-text.js';
import globalState from '../global-state.js';
import { client } from '../prismic-configuration';
import Metadata from '../helpers/Metadata';

export default class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: [],
    };
  }

  componentDidMount() {
    const has = globalState.get('about');
    window.scrollTo(0, 0);
    this.hashLinkScroll();
    if (has) {
      this.setContent(has);
    } else {
      this.getContent();
    }
  }

  setContent(state) {
    this.setState(state);
  }

  getContent() {
    const self = this;
    client.getByType('about')
      .then(data => {
        const res = _.get(data, 'results[0].data');
        if (res && Object.keys(res).length > -1) {
          const state = {
            title: _.get(res, 'title[0].text'),
            body: _.get(res, 'body'),
            employeeTitle: _.get(res, 'employee_title[0].text'),
            employees: _.get(res, 'employees'),
            metadata: {
              data: { description_metadata: _.get(res, 'description_metadata') },
            },
          };

          self.setContent(state);
          globalState.set('about', state);
        }
      })
      .catch(function(err) {
        console.log(err);
      });
  }

  hashLinkScroll() {
    const { hash } = window.location;
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
          window.scrollBy(0, -110);
        }
      }, 0);
    }
  }

  render() {
    return (
      <section>
        {this.state.metadata && <Metadata data={this.state.metadata} title="About" />}
        <div className="padding-vertical-medium-l">
        {this.state.title && <Heading text={this.state.title} customClass="margin-bottom-medium"/>}
        {this.state.body && <RichText text={this.state.body} customClass="paragraph margin-bottom-xlarge content-max-width"/>}

        {this.state.employees.length > 0 &&
          <div className="margin-top-xlarge">
            {this.state.employeeTitle && <Heading2 text={this.state.employeeTitle} />}
            <div className="employees grid-container wrap margin-top-small margin-bottom-xlarge padding-vertical-xsmall-s break-column-s">
              {this.state.employees.map(function(emp, index) {
                return <Employee info={emp.employees} key={index} />
              }, this)}
            </div>
          </div>
        }

        <a id="contact">
          <ContactForm />
        </a>
        </div>
      </section>
    );
  }
}