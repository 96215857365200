import React from 'react';
import _ from 'lodash';

import globalState from '../global-state.js';
import { client } from '../prismic-configuration';
import Metadata from '../helpers/Metadata';
import { Heading } from '../components/Headings';
import BlogProvider from '../components/Blog/BlogProvider';
import BlogItem from '../components/Blog/BlogItem';
import Hero from '../components/Hero/Hero';

import '../dist/styles/blog.css';

export default class BlogLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: null,
      sections: [],
      hero: [],
    };
  }

  componentDidMount() {
    const has = globalState.get('blog');
    if (has) {
      this.setContent(has);
    } else {
      this.getContent();
    }
  }

  getContent() {
    const self = this;
  	client.getByType('blog_page')
      .then(data => {
        const res = _.get(data, 'results[0].data');
        if (res) {
          const blogItems = _.filter(res.body, {slice_type: 'blog_section'});
          const hero = _.filter(res.body, {slice_type: 'hero'});
          const obj = {
            title: _.get(res, 'title[0].text'),
            metadata: res,
            hero: _.get(hero, '[0].items'),
            sections: _.map(blogItems, (section) => {
              return {
                title: _.get(section, 'primary.section_title[0].text'),
                provider: _.get(section, 'primary.provider'),
                items: _.get(section, 'items'), 
              }
            })
          }

          return obj;
        }
      })
      .then((obj) => {
        if (obj.hero.length > 0) {
          const promises = _.map(obj.hero, (hero) => {
            const uid = _.get(hero, 'featured_item.uid');
            return client.getByUID('blog_post', uid);
          });

          return Promise.all(promises)
            .then((items) => {
              obj.hero = _.map(items, (blog) => {
                const additional = _.find(obj.hero, (h) => _.get(h, 'featured_item.uid') === blog.uid);
                const title = _.get(additional, 'hero_title');
                const image = _.find(blog.data.body, (data) => {
                  return data.slice_type === 'feature_image';
                });
                const body = _.get(additional, 'hero_body');

                return {
                  title: _.get(title, '[0].text.length') > 0 ? title : _.get(blog, 'data.title'),
                  body: _.get(body, '[0].text.length') > 0 ? body : _.get(blog, 'data.description'),
                  image: _.get(additional, 'hero_image') || _.get(image, 'primary.image'),
                  button: {
                    text: _.get(additional, 'hero_button[0].text'),
                    link: `/media/${_.get(blog, 'data.provider.uid')}/${_.get(blog, 'uid')}`,
                  },
                  alignment: _.get(additional, 'alignment'),
                  color_scheme: _.get(additional, 'color_scheme'),
                }
              })

              return obj;
            })
        } else {
          return obj;
        }
      })
      .then((obj) => {
        self.setContent(obj);
      });
  }

  setContent(state) {
    this.setState(state);
  }

  width(total) {
    const multiplier = window.innerWidth > 900 ? 4 : 1.5;
    return total > multiplier ? (total * 100) / multiplier + 'vw' : null;
  }

  render() {
    const { metadata, hero, title, sections } = this.state;
    return (
      <div>
        {metadata && <Metadata data={metadata} title={title} />}
        {hero.length > 0 && <Hero data={hero} />}
      	<section className="padding-vertical-none">
          {title && <div className="padding-vertical-large-l padding-vertical-small">
            <Heading text={title} customClass="padding-vertical-medium-l padding-bottom-small" />
          </div>}

          {sections.length > 0 && sections.map(function(section, index) {
            return (
              <div className="margin-bottom-large" key={index}>
                {section.provider && <div className="padding-vertical-large-l padding-vertical-small">
                  <BlogProvider data={section.provider} type="snippet" />
                </div>}
                {section.items.length > 0 && 
                  <div className="slider">
                    <div className="blog-items-scroller grid-container padding-vertical-small-l" style={{width: this.width(section.items.length)}}>
                      {section.items.map(function(blog, i) {
                        return <div className="grid-4-m padding-vertical-small padding-bottom-medium" key={index + i}>
                          <BlogItem content={blog} uid={blog.blog_post.uid} type="widget" />
                        </div>
                      }, this)}
                    </div>
                  </div>
                }
              </div>
            )
          }, this)}
        </section>
      </div>
  	)
  }
}
