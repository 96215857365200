import React from 'react';
import _ from 'lodash';
import '../styles/artist.scss';
import { client } from '../prismic-configuration';
import ResponsiveImage from './responsive-image.js';

export default class Artist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseURL: '',
      url: '',
      uid: '',
      name: null,
      mobileImage: null,
      desktopImage: null,
      hidden: true
    };
    this.getInfo();
  }

  componentDidMount() {
    const id = _.get(this, 'props.params.params.id');
    const path = _.get(this, 'props.params.path');
    if (id) this.setState({url: id});
    if (path) {
      const index = path.indexOf(':id') > -1 ? path.indexOf(':id') - 1 : path.length;
      const base = path.substring(0, index) + '/';
      if (base) this.setState({baseURL: base});
    }
  }

  getInfo(prevProps) {
    const self = this;
    const id = _.get(this, 'props.info.id');
    client.getByID(id)
      .then(data => {
        const res = _.get(data, 'data');
        if (res && Object.keys(res).length > -1) {
          self.setState({
            uid: _.get(data, 'uid'),
            name: _.get(res, 'name[0].text'),
            image: _.get(res, 'image'),
            thumbnailImage: _.get(res, 'image.thumbnail.url'),
            bio: _.get(res, 'bio'),
            socialLinks: _.get(res, 'social_links'),
            metadata: res,
          });
        }
      })
      .catch(function(err) {
        console.log(err);
      });
  }

  render() {
    return (
      <div className="grid-4-l grid-2-m grid-1">
        <a href={this.state.baseURL + this.state.uid} className="artist">
          <div className="padding-vertical-small padding-bottom-medium">
            {this.state.image && 
              <div className="artist_image margin-bottom-small">
                <ResponsiveImage image={this.state.image} thumbnail={this.state.thumbnailImage} />
              </div>
            }
            <div className="artist_content padding-bottom-small">
              {this.state.name && <h3>{this.state.name}</h3>}
            </div>
          </div>
        </a>
      </div>
    );
  }
}
