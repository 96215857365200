import React from 'react';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';

import { Heading3 } from './Headings';
import ResponsiveImage from './responsive-image';
import RichText from './rich-text';

import '../styles/article.scss';

export default class NewsItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thumbnailImage: null,
      imageAlt: null,
      title: null,
      date: null,
      snippet: null,
      uid: null,
    };
  }

  componentDidMount() {
    const path = _.get(this, 'props.params.path');
    const data = _.get(this, 'props.data');
    let baseURL = '';

    if (path) {
      const index = path.indexOf(':id') > -1 ? path.indexOf(':id') - 1 : path.length;
      baseURL = path.substring(0, index) + '/';
      if (baseURL) this.setState({ baseURL });
    }

    if (data) {
      this.setState({
        thumbnailImage: _.get(data, 'data.image.thumbnail.url'),
        image: _.get(data, 'data.image'),
        title: _.get(data, 'data.title[0].text'),
        date: this.date(),
        snippet: this.snippet(),
        uid: _.get(data, 'uid'),
        url: baseURL + _.get(data, 'uid'),
      });
    }    
  }

  date() {
    let date = _.get(this, 'props.data.data.date');
    if (date){
      date = date.split('-');
      date = [].concat(date[1], date[2], date[0]).join('-').toString();
    }
    return date;
  }

  snippet() {
    const snippet = _.get(this, 'props.data.data.snippet');
    return snippet ? snippet : _.get(this, 'props.data.data.body', []);
  }

  hasImage() {
    return !_.isNil(this.state.image) || !_.isNil(this.state.thumbnailImage);
  }

  render() {
    const { url, image, thumbnailImage, title, date, snippet } = this.state;
    return (
      <div className="article padding-medium padding-bottom-none margin-bottom-medium">
        {url && 
          <NavLink to={url}>
            {this.hasImage() && <ResponsiveImage
              customClass="article_image"
              image={image}
              thumbnail={thumbnailImage}
            />}
            <div className="article_content padding-bottom-medium">
              {title && <Heading3 text={title} customClass="margin-top-small" />}
              {date && <p className="body-small margin-bottom-small">{date}</p>}
              {snippet && <RichText text={snippet} />}
            </div>
          </NavLink>
        }
      </div>
    )
  }
}
