import React from 'react';
import '../styles/card.scss';
import ResponsiveImage from './responsive-image.js';
import { IconExternal } from './Icons.js';

export default function Card(props) {
  const { link, image, thumbnailImage, title, subtitle, subtext } = props;
  const { url, target } = link ?? {};

  return (
    <div className="grid-4-l grid-2-m grid-2">
      <a className="card" href={url} target={target}>
        <div className="padding-vertical-medium-l padding-vertical-small padding-bottom-medium">
          {image && 
            <div className="margin-bottom-small">
              <ResponsiveImage
                image={image}
                thumbnail={thumbnailImage}
              />
            </div>
          }
          <div className="card_title padding-bottom-small">
            {title && <h3>{title} {target === '_blank' && <IconExternal size="small" />}</h3>}
            {subtitle && <p>{subtitle}</p>}
            {subtext && <span className='price body-small padding-bottom-medium'>{subtext}</span>}
          </div>
        </div>
      </a>
    </div>
  );
}
