import React from 'react';
import { Link } from 'react-router-dom';

import { IconTwitter, IconInstagram, IconBandcamp } from './Icons.js';
import '../styles/footer.scss';

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-container">
          <div className="grid-30-large column">
            <p className="bold">
              &copy; Lonely Ghost Records
            </p>
            <div className="footer-links">
              <Link to="/about" className="footer-link">About</Link>
              <Link to="/about#contact" className="footer-link">Contact us</Link>
            </div>
          </div>
          <div className="grid-70-large footer-links_social">
            <a href="https://www.twitter.com/lonelyghostrecs/" target="blank" className="footer-link_icon">
              <IconTwitter size="medium" />
            </a>
            <a href="https://lonelyghostrecords.com/artists" target="blank" className="footer-link_icon">
              <IconBandcamp size="medium" />
            </a>
            <a href="https://www.instagram.com/lonelyghostrecs/" target="blank" className="footer-link_icon">
              <IconInstagram size="medium" />
            </a>
            {
              //<a href="https://www.facebook.com/lonelyghostrecords/" className="footer-link_icon">
                          //<IconFacebook size="medium" />
                        //</a>
            }
          </div>
        </div>
      </footer>
    );
  }
}
