import _ from 'lodash';

import { client } from '../prismic-configuration';

export async function getArtistsData() {
  const data = await client.getByType('artist', {
    pageSize: 999,
    orderings: [
      {
        field: 'my.artist.name'
      }
    ]
  });

  if (data.results) {
    return data.results.map((artist) => {
      return {
        uid: _.get(artist, 'uid'),
        id: _.get(artist, 'id'),
        name: _.get(artist, 'data.name[0].text'),
        image: _.get(artist, 'data.image'),
      }
    });
  }

  return [];
}

export async function getArtistData(id) {
  const data = await client.getByUID('artist', id);
  if (data.data && Object.keys(data.data).length > -1) {
    return {
      uid: _.get(data, 'uid'),
      id: _.get(data, 'id'),
      name: _.get(data, 'data.name[0].text'),
      image: _.get(data, 'data.image'),
      thumbnailImage: _.get(data, 'data.image.thumbnail.url'),
      bio: _.get(data, 'data.bio'),
      metadata: data,
    };
  }

  return {};
}