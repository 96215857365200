import React from 'react';
import * as prismic from '@prismicio/client';
import _ from 'lodash';

import LazyLoad from 'react-lazy-load';
import { client } from '../prismic-configuration';
import globalState from '../global-state.js';
import Metadata from '../helpers/Metadata';
import { Heading2 } from '../components/Headings';
import SecondaryNav from '../components/SecondaryNav';
import { default as Provider} from '../components/Blog/BlogProvider';
import BlogItem from '../components/Blog/BlogItem';
import Filters from '../components/Filters';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export default class BlogProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      items: [],
      filteredItems: null,
      name: null,
      filters: [],
      error: false,
    };
  }

  componentDidMount() {
    const uid = _.get(this, 'props.params.match.params.id');
    this.setState({ uid });
    this.getContent(uid);
    this.getLinks();
  }

  componentDidUpdate(prevProps) {
    const uid = _.get(this, 'props.params.match.params.id');
    const prev = _.get(prevProps, 'params.match.params.id');

    if (uid !== prev) {
      this.setState({
        id: null,
        items: [],
        filteredItems: null,
        name: null,
        filters: [],
        error: false,
        uid,
      })
      this.setState({ uid });
      this.getContent(uid);
      this.getLinks();
    }
  }

  getContent(uid) {
    const self = this;
    client.getByUID('blog_provider', uid)
      .then(data => {
        const body = _.get(data, 'data');
        if (body) {
          const filters = _.get(body, 'filters');
          self.setState({
            id: _.get(data, 'id'),
            name: _.get(body, 'provider_name'),
            metadata: body,
          }, () => self.getItems(filters));
        } else {
          self.setState({
            error: true,
          })
        }
      });
  }

  getLinks() {
    const self = this;
  	client.getByType('blog_provider')
      .then(data => {
        const res = _.get(data, 'results');
        if (res) {
        	const sorted = _.sortBy(res, 'uid');
        	let providers = _.map(sorted, (provider) => {
            return {
            	url: provider.uid,
            	text: _.get(provider, 'data.provider_name'),
            }
          });

          self.setState({secondaryNavLinks: providers});
        }
      });
  }

  getItems(pageFilters) {
    const self = this;
    const { id } = this.state;

    client.get({
      filters: [
        prismic.filter.at('document.type', 'blog_post'),
        prismic.filter.at('my.blog_post.provider', id),
      ],
      orderings: [
        {
          field: 'my.blog_post.date',
          direction: 'desc',
        },
        {
          field: 'document.first_publication_date',
          direction: 'desc',
        }
      ],
      pageSize: 100,
    })
    .then(res => {
      const items = _.get(res, 'results');
      let filters = [];
      let authors = [];
      let dates = [];
      const tags = [];

      _.forEach(items, (item) => {
        const i = _.get(item, 'uid');
        const author = _.get(item, 'data.author');
        const date = _.get(item, 'data.date');

        if (author && authors.indexOf(author) <= -1) authors.push(author);
        if (date) {
          const parsed = this.parsedDate(date);
          item.data.filterDate = parsed;
          dates.push(parsed)
        }

        globalState.set(`blog:${i}`, item);
      });

      _.forEach(pageFilters, (filter) => {
        const name = _.get(filter, 'filter');
        if (name === 'author') {
          filters.push({
            type: 'unique',
            default: _.capitalize(name),
            key: 'author',
            items: authors,
          });
        } else if (name === 'date') {
          filters.push({
            type: 'unique',
            default: _.capitalize(name),
            key: 'filterDate',
            items: _.uniq(dates),
          });
        } else if (!_.isNil(name)) {
          tags.push(name);
        }
      });

      if (tags.length > 0) {
        filters.unshift({
          type: 'multi',
          items: _.uniq(tags),
        });
      }

      self.setState({
        items,
        filteredItems: _.cloneDeep(items),
        filters,
        error: false,
      });
    })
  }

  setContent() {

  }

  parsedDate(date) {
    const d = new Date(date);
    const month = months[d.getMonth()];
    const year = d.getFullYear();
    return `${month} ${year}`;
  }

  setFilteredItems(items) {
    this.setState({
      filteredItems: items,
    });
  }

  render() {
    const { id, items, filteredItems, filters, name } = this.state;
    return (
      <div>
        {this.state.metadata && <Metadata data={this.state.metadata} title={name} />}
        {this.state.secondaryNavLinks && <SecondaryNav links={this.state.secondaryNavLinks} />}
      	<section>
          {id && <Provider data={this.state} />}
          {filters.length > 0 && <Filters
              filters={filters}
              items={items}
              filteredItems={filteredItems}
              callback={this.setFilteredItems.bind(this)} />}
          
          <div className="grid-container break-column-s wrap padding-vertical-small-l margin-top-small">
            {filteredItems && filteredItems.length > 0 && filteredItems.map(function(blog, index) {
              return <div className="grid-4-m grid-2 padding-bottom-medium margin-bottom-medium-l" key={blog.uid}>
                  <LazyLoad height={500}>
                    <div className="padding-vertical-small"><BlogItem uid={blog.uid} type="widget" /></div>
                  </LazyLoad>
                </div>
            }, this)}
            {filteredItems && filteredItems.length <= 0 && <Heading2 customClass="padding-vertical-small-l" text="0 results found, please try a different filter" />}
          </div>
        </section>
        {this.state.error && <section>
          <Heading2 text="An error has occurred, please try again later" />
        </section>}
      </div>
  	)
  }
}
