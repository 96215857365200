import React from 'react';
import _ from 'lodash';

import { Heading3 } from './Headings';

import '../styles/filters.scss';

export default class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [],
      filters: [],
      selectedFilters: [],
      selectedSorts: [],
    };
    this._setShow = function() {
      this.setState({
        show: this.show()
      });
    }.bind(this)
  }

  componentDidMount() {
    this.setFilters();
  }

  setFilters() {
    const { filters } = this.props;
    const modified = _.map(filters, (filter) => {
      filter.items = _.map(filter.items, (item) => (
        _.isString(item)
          ? {
            name: _.capitalize(item),
            value: item,
          }
          : item
      ));

      return filter;
    });

    let selected = [];
    _.forEach(modified, (m) => {
      if (m.type === 'multi' || m.type === 'multiselect') {
        _.forEach(m.items, (item) => {
          selected.push({name: item.value, selected: true, type: m.key});
        })
      }
    });

    this.setState({
      filters: modified,
      selectedFilters: selected,
    });
  }

  filter(event) {
    let { callback } = this.props;
    let { selectedFilters, selectedSorts } = this.state;
    const target = event.currentTarget;
    const type = target.value;
    const checked = _.isNil(target.checked) ? true : target.checked;
    const unique = _.isNil(target.checked);
    const parentType = target.getAttribute('data-type');
    const index = _.findIndex(selectedFilters, {name: type});

    if (unique && parentType) {
      const obj = {
        type: parentType,
        value: type,
      }
      const prev = _.findIndex(selectedFilters, {type: parentType});
      if (prev > -1) {
        selectedFilters[prev] = obj;
      } else {
        selectedFilters.push(obj);
      }
    } else {
      if (index > -1) {
        selectedFilters[index].selected = checked;
      } else {
        selectedFilters.push({
          type: parentType,
          name: type,
          selected: checked,
        });
      }
    }
    
    this.setState({
      selectedFilters,
    });

    if (_.isFunction(callback)) {
      callback(selectedFilters, selectedSorts);
    }
  }

  sort(event) {
    let { callback } = this.props;
    let { selectedFilters, selectedSorts } = this.state;
    const target = event.currentTarget;
    const type = target.value;
    const parentType = target.getAttribute('data-type');
    const index = _.findIndex(selectedSorts, {name: type});

    const obj = {
      type: parentType,
      value: type,
    }
    const prevIndex = _.findIndex(selectedSorts, {type: parentType});
    if (type) {
      if (prevIndex > -1) {
        selectedSorts[prevIndex] = obj;
      } else {
        selectedSorts.push(obj);
      }
    } else if (prevIndex > -1) {
      selectedSorts.splice(index, 1);
    }

    this.setState({
      selectedSorts,
    });

    if (_.isFunction(callback)) {
      callback(selectedFilters, selectedSorts);
    }
  }

  height() {
    const self = this;
    setTimeout(() => {
      const filter = document.querySelector('.filter-control');
      if (!filter) return;
      self.setState({height: filter.offsetHeight});
    });
  }

  render() {
    const { filters } = this.state;
    return (
      <div className="filters">
        {filters && filters.map(function(filter, index) {
          if (filter.type === 'unique' && filter.items.length > 0) {
            return (
              <div className="filter-control_unique" key={index}>
                <Heading3 text={filter.default}></Heading3>
                <select className="filter-control_dropdown" data-type={filter.key} onChange={this.filter.bind(this)} key={index}>
                  {filter.default && <option value="">{filter.default}</option>}
                  {filter.items.map(function(f, i) {
                    return <option value={f.value} key={f.value}>{f.name}</option>
                  }, this)}
                </select>
              </div>
            )
          } else if (filter.type === 'multi') {
            return (
              <div className="filter-control_multi" key={index}>
                <Heading3 text={filter.default}></Heading3>
                <div className="filter-control_select" key={index}>
                  {filter.items.map(function(f, i) {
                    return (
                      <label className="checkbox" key={f.value}>
                        <input type="checkbox" defaultChecked={true} value={f.value} onChange={this.filter.bind(this)} />{f.name}
                        <span></span>
                      </label>
                    )
                  }, this)}
                </div>
              </div>
            )
          } else if (filter.type === 'multiselect') {
            return (
              <div className="filter-control_selectmulti" key={index}>
                <Heading3 text={filter.default}></Heading3>
                {filter.items.map(function(f, i) {
                  return (
                    <label className="checkbox" key={f.value}>
                      <input type="checkbox" defaultChecked={true} value={f.value} onChange={this.filter.bind(this)} />{f.name}
                      <span></span>
                    </label>
                  )
                }, this)}
              </div>
            )
          } else if (filter.type === 'sort') {
            return (
              <div className="filter-control_sort" key={index}>
                <Heading3 text={filter.default}></Heading3>
                <select className="filter-control_dropdown" data-type={filter.key} onChange={this.sort.bind(this)} key={filter.key}>
                  {filter.items.map(function(f, i) {
                    return <option value={f.value} key={f.value}>{f.name}</option>
                  }, this)}
                </select>
              </div>
            )
          } else {
            return <></>
          }
        }, this)}
      </div>
    )
  }
}
