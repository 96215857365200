import React from 'react';
import _ from 'lodash';

import ResponsiveImage from '../responsive-image.js';
import { Heading3 } from '../Headings.js';

import '../../styles/playlist.scss';

function full() {
	const { data } = this.props;
	const embedded = _.get(data, 'html') || _.get(data, 'body[0].primary.link.html');

  return (
  	<div className="playlist" dangerouslySetInnerHTML={{__html: embedded}} />
  )
}

function widget() {
	const { data } = this.props;
	const additional = _.get(data, 'primary');
	const image = _.get(data, 'image') || _.get(additional, 'link.thumbnail_url');
	const title = _.get(data, 'title') || _.get(additional, 'link.title');
	const date = _.get(data, 'date');
	const url = _.get(data, 'url');
	const type = _.get(data, 'type');
	const isFeatured = _.get(data, 'featured', false);

	return (
		<div className="blog-widget_music">
			{!isFeatured && <a href={url}>
				{image && <div className="blog-widget_image">
					{_.isObject(image) && <ResponsiveImage image={image} />}
					{_.isString(image) && <img src={image} alt={title} />}
				</div>}
				{type && <p className="tag">{type}</p>}
				{title && <Heading3 text={title} />}
				{date && <p className="body-small">{date}</p>}
			</a>}
			{isFeatured && full.call(this)}
		</div>
	)
}

export default class Playlist extends React.Component {
	render() {
		if (this.props.type === 'widget') {
			return widget.call(this);
		}

		return full.call(this);
  }
}
