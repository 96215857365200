import React from 'react';
import '../../styles/hero.scss';

import HeroItem from './HeroItem';

export default class Hero extends React.Component {
  render() {
    let heros = this.props.data;
    if (heros && heros.length > 5) {
      heros = heros.slice(0, 5);
    }
    return (
      <div className="hero">
        {heros && heros.map(function(hero, index) {
          return <HeroItem data={hero} key={index} />
        }, this)}
      </div>
    );
  }
}
