import React from 'react';
// import _ from 'lodash';

import { Heading2 } from './Headings.js';

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validate = this.validate.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
  }

  state = {
    responseError: '',
    responseSuccess: '',
    error: [],
    disabled: true,
    name: {
      value: '',
      error: false
    },
    email: {
      value: '',
      error: false
    },
    subject: {
      value: '',
      error: false
    },
    message:{
      value: '',
      error: false
    },
  }

  sendEmail(e) {
    e.preventDefault();
    if (this.state.disabled || this.state.sending) return;
    const body = {
      name: this.state.name.value,
      email: this.state.email.value,
      subject: this.state.subject.value,
      message: this.state.message.value,
    }

    this.setState({
      sending: true,
      responseSuccess: '',
      responseError: ''
    });

    fetch('/email', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })
    .then(this.handleResponse)
    .catch(this.handleResponse)
  }

  handleResponse(res) {
    this.setState({sending: false});
    let status = res.status;
    if (status === 200) {
      this.setState({
        responseSuccess: 'Email sent!',
        responseError: ''
      });
    } else {
      this.setState({
        responseError: 'An error has occurred. Please try again later or send an email to lonelyghostjams@gmail.com',
        responseSuccess: ''
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: {value},
      responseSuccess: '',
      responseError: ''
    });
  }

  validate(event) {
    const target = event.target;
    const emailTest = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/i;
    let currentError = this.state.error;
    let error;

    if (target.name === 'email') {
      error = emailTest.test(target.value) ? null : `Please enter your ${target.name}`;
    } else {
      let your = target.name === 'name' ? 'your' : 'a';
      error = target.value.length > 0 ? null : `Please enter ${your + ' '} ${target.name}`;
    }

    if (currentError.indexOf(error) <= -1) {
      currentError.push(error);
    }

    this.setState({currentError});
    this.setState({
      [target.name]: {error, value: target.value}
    });

    if (this.state.name.value && !this.state.name.error &&
        this.state.email.value && !this.state.email.error &&
        this.state.subject.value && !this.state.subject.error &&
        this.state.message.value && !this.state.message.error)
    {
      this.setState({
        disabled: false
      });
    } else {
      this.setState({
        disabled: true
      });
    }
  }

  divClass(type, c) {
    c += ' grid-container column form_input';
    return this.state[type].error ? 'form_error ' + c : c;
  }

  render() {
    return (
      <form id="contact" className="form contact padding-vertical-medium-m" onSubmit={this.sendEmail}>
        <Heading2 text="Contact us" />
        <div className="grid-container wrap">
          <div className={this.divClass('name', 'grid-1 grid-2-m')}>
            <label htmlFor="name">Name</label>
            <input type="text" name="name" className="margin-right-small-m"
              value={this.state.name.value}
              onChange={this.handleInputChange}
              onBlur={this.validate} />
            {this.state.name.error && <div className="form_error-message grid-1">{this.state.name.error}</div>}
          </div>
          <div className={this.divClass('email', 'grid-1 grid-2-m')}>
            <label htmlFor="email">Email</label>
            <input type="text" name="email"
              value={this.state.email.value}
              onChange={this.handleInputChange}
              onBlur={this.validate} />
            {this.state.email.error && <div className="form_error-message grid-1 grid-2-m">{this.state.email.error}</div>}
          </div>
          <div className={this.divClass('subject', 'grid-1')}>
            <label htmlFor="subject">Subject</label>
            <input type="text" name="subject" 
              value={this.state.subject.value}
              onChange={this.handleInputChange}
              onBlur={this.validate} />
            {this.state.subject.error && <div className="form_error-message grid-1 grid-2-m">{this.state.subject.error}</div>}
          </div>
          <div className={this.divClass('message', 'grid-1')}>
            <label htmlFor="message">Message</label>
            <textarea name="message" 
              value={this.state.message.value}
              onChange={this.handleInputChange}
              onBlur={this.validate} />
            {this.state.message.error && <div className="form_error-message grid-1 grid-2-m">{this.state.message.error}</div>}
          </div>
        </div>
        {!this.state.responseSuccess && <button className={this.state.disabled ? 'disabled button' : 'button'}>
          <input type="submit" value={this.state.sending ? "Sending" : "Send"} />
          {this.state.sending && 
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
          }
        </button>}
        {this.state.responseError && <div className="form_error-response margin-top-small">{this.state.responseError}</div>}
        {this.state.responseSuccess && <div className="form_success-response">{this.state.responseSuccess}</div>}
      </form>
    )
  }
}