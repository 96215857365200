import React from 'react';
import { Heading } from './Headings';

export default function PageError() {
  return (
    <div className="page-error">
      <Heading text="An error has occurred. Please try again later." />
    </div>
  );
}
