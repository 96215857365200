import React from 'react';
// import _ from 'lodash';

import RichText from '../rich-text.js';
import { Heading3 } from '../Headings.js';
import ResponsiveImage from '../responsive-image.js';

function full() {
	const { data } = this.props;

	return (
		<div className="blog-body">
			{data && <RichText text={data} />}
		</div>
	)
}

function widget() {
	const { image, type, title, date, url } = this.props.data;

	return (
		<div className="blog-widget_blog">
			{image && <a href={url}><ResponsiveImage image={image} customClass="blog-widget_image" /></a>}
			{type && <p className="tag">{type}</p>}
			{title && <a href={url}><Heading3 text={title} /></a>}
			{date && <p className="body-small">{date}</p>}
		</div>
	)
}

export default class Blog extends React.Component {
	render() {
		if (this.props.type === 'widget') {
			return widget.call(this);
		}

		return full.call(this);
  }
}
