import React from 'react';
import _ from 'lodash';

import ResponsiveImage from '../responsive-image.js';
import { Heading3 } from '../Headings.js';

export default class Item extends React.Component {
  divClass() {
    let c = 'flex featured-item';
    c += this.props.type ? '_'+this.props.type : '';
    c += this.props.isActive ? ' featured-item_active ' : '';
    return c;
  }

  image() {
    return _.get(this, 'props.data.data.image');
  }

  title() {
    return _.get(this, 'props.data.data.title[0].text');
  }

  artist() {
    return _.get(this, 'props.data.data.artist');
  }

  date() {
    return _.get(this, 'props.data.data.date[0].text');
  }

  button() {
    let button = _.get(this, 'props.data.data.button[0]');
    let target = _.get(button, 'spans[0].data.link_type') === 'Web' ? '_blank' : '';
    if (button) {
      return {
        text: button.text,
        url: _.get(button, 'spans[0].data.url'),
        target,
      }
    } else {
      return {};
    }
  }

  id() {
    return _.get(this, 'props.data.id');
  }

  render() {
    if (this.button().url) {
      return (
        <a
          href={this.button().url}
          className={this.divClass()}
          data-id={this.id()}
          data-index={this.props.index}
          target={this.button().target}
          rel={this.button().target === "_blank" ? "noopener" : ""}>

          {this.image() && 
            <ResponsiveImage
              image={this.image()}
            />
          }
          <div className="featured-item_content">
            {this.title() && <Heading3 text={this.title()} />}
            {this.artist() && <p>{this.artist()}</p>}
            {this.date() && <p className="body-small">{this.date()}</p>}
            {this.button().url && this.button().text && <button>{this.button().text}</button>}
          </div>
        </a>
      )
    } else {
      return null;
    }
  }
}
