import React from 'react';
import _ from 'lodash';
import * as prismic from '@prismicio/client';

import Hero from '../components/Hero';
import Features from '../components/Features';
import FeaturedNews from '../components/FeaturedNews';
import FeaturedEvents from '../components/FeaturedEvents';
import PageError from '../components/PageError';
import globalState from '../global-state';

import { client } from '../prismic-configuration';
import Metadata from '../helpers/Metadata';

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      children: {
        Hero: false,
        Features: false,
        FeaturedNews: false,
        FeaturedEvents: false,
      },
    };
    this.childMounted = this.childMounted.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const has = globalState.get('home');
    if (has) {
      this.setContent(has);
    } else {
      this.getContent();
    }
  }

  setContent(state) {
    this.setState(state);
  }

  getContent() {
    const self = this;
    if (client) {
      client.get({
        filters: [
          prismic.filter.at('document.type', 'home_page')
        ]
      })
      .then((data) => {
        const res = _.get(data, 'results[0].data');
        if (res && Object.keys(res).length > -1) {
          const obj = {
            hero: _.get(res, 'hero'),
            eventsTitle: _.get(res, 'events_title[0].text'),
            events: _.get(res, 'featured_events'),
            newsTitle: _.get(res, 'news_title[0].text'),
            news: _.get(res, 'featured_news'),
            releaseTitle: _.get(res, 'release_title[0].text'),
            releases: _.get(res, 'featured_releases'),
            metadata: {
              data: { description_metadata: _.get(res, 'description_metadata') },
            },
          };

          self.setState(obj);
          globalState.set('home', obj);
        }
      })
      .catch(() => {
        self.setState({ error: true });
      });
    }
  }

  childMounted(child) {
    let allMounted = true;
    const state = this.state;
    state.children[child] = true;
    this.setState(state);

    Object.keys(state.children).forEach((c) => {
      if (c === false) allMounted = false;
    });

    if (allMounted) this.props.setLoaderState(false);
  }

  render() {
    const { hero, releases, news, events, releaseTitle, newsTitle, eventsTitle, error } = this.state;
    return (
      <div className="home">
        {this.state.metadata && <Metadata data={this.state.metadata} />}
        {hero && <Hero data={hero} childMounted={this.childMounted} />}
        {releases && <Features data={releases} title={releaseTitle} childMounted={this.childMounted} />}
        {news && <FeaturedNews data={news} title={newsTitle} childMounted={this.childMounted} />}
        {events && <FeaturedEvents data={events} title={eventsTitle} childMounted={this.childMounted} />}
        {error && <PageError /> }
      </div>
    );
  }
}
