import React from 'react';
import _ from 'lodash';

import { client } from '../prismic-configuration';
import { Heading, Heading4 } from './Headings.js';
import globalState from '../global-state.js';
import RichText from './rich-text.js';
import { getURL } from '../helpers/utility';

import '../styles/event.scss';

function RenderButton(props) {
  const { button } = props;
  const external = !_.isString(button);
  if (external) {
    return <RichText text={button} customClass="button" />;
  }

  return <span className="button" dangerouslySetInnerHTML={{__html: button}} />
}

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.title(),
      date: this.date(),
      location: this.location(),
      link: this.link(),
      buttonLink: null,
    };
    this.button();
  }

  title() {
    return _.get(this, 'props.data.data.artists[0].text');
  }

  date() {
    let date = _.get(this, 'props.data.data.date');
    if (date){
      date = date.split('-');
      date = [].concat(date[1], date[2], date[0]).join('-').toString();
    }
    return date;
  }

  location() {
    return _.get(this, 'props.data.data.location[0].text');
  }

  link() {
    const uid = _.get(this, 'props.data.data.article_link.uid');
    return uid ? '/news/' + uid : null;
  }

  async button() {
    const data = _.get(this, 'props.data.data.ticket_link');
    const hyperlink = _.get(data, '[0].spans[0].data.url');
    const internalLink = _.get(data, '[0].spans.[0].data.id');

    if (hyperlink) {
      this.state.buttonLink = data;
    } else if (internalLink) {
      const element = await getURL.call(this, data[0]);
      this.setState({
        buttonLink: element,
      });
    }
  }

  renderButton() {
    const TestButton = this.state.button;
    return <TestButton />
  }

  render() {
    const { title, date, location, buttonLink } = this.state;
    return (
      <a href={this.link()} className="featured-item_event grid-3-s">
        {title && <Heading4 text={title} />}
        {date && <p>{date}</p>}
        {location && <p className="body-small">{location}</p>}
        {buttonLink && <RenderButton button={buttonLink} />}
      </a>
    )
  }
}

export default class FeaturedEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      features: [],
    };
  }

  componentDidMount() {
    const has = globalState.get('events');
    if (has) {
      this.setContent(has);
    } else {
      this.getContent();
    }
  }

  setContent(state) {
    this.setState(state);
  }

  getContent() {
    const self = this;
    const data = this.props.data;
    if (data) {
      const queries = _.map(data, function(value) {
        const id = _.get(value, 'event.id');
        return client.getByID(id);
      });

      Promise.all(queries)
        .then(function(data) {
          if (data && data.length > 0) {
            const obj = {features: data.slice(0, 3)};
            self.setContent(obj);
            globalState.set('events', obj);
            self.props.childMounted('FeaturedEvents');
          }
        })
        .catch(function(err) {
          console.log('error', err);
        });
    }
  }

  render() {
    if (this.state.features.length > 0) {
     return (
        <section className="feature-container_events">
          <Heading type="heading" text={this.props.title} />
          <div className="slider padding-left-small">
            <div className="feature-container_items grid-container">
              {this.state.features && this.state.features.map(function(feature, index) {
                return <Item data={feature} key={index} />
              })}
            </div>
          </div>
        </section>
      ); 
    } else {
      return null;
    }
  }
}
