import React from 'react';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';

import Metadata from '../helpers/Metadata';
import { Heading2 } from './Headings';
import ResponsiveImage from './responsive-image';
import RichText from './rich-text';
import { IconClose } from './Icons';

import '../styles/article.scss';

export default class Article extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseURL: '',
      uid: '',
    };

    this.container = React.createRef();
  }

  componentDidMount() {
    const id = window.location.pathname.split(`/`)[2];
    this.setContent();

    if (id && id === _.get(this, 'props.data.uid')) {
      this.toggleDetail();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setContent();
    }
  }

  setContent() {
    const path = _.get(this, 'props.params.path');
    const data = _.get(this, 'props.data');
    let baseURL;

    if (path) {
      const index = path.indexOf(':id') > -1 ? path.indexOf(':id') - 1 : path.length;
      baseURL = path.substring(0, index) + '/';
      if (baseURL) this.setState({ baseURL });
    }

    if (data) {
      this.setState({
        image: _.get(data, 'data.image'),
        title: _.get(data, 'data.title[0].text'),
        date: this.date(),
        snippet: this.snippet(),
        body: _.get(data, 'data.body', []),
        uid: _.get(data, 'uid'),
        previous: _.get(this, 'props.prev.uid') ? baseURL + _.get(this, 'props.prev.uid') : null,
        next: _.get(this, 'props.next.uid') ? baseURL + _.get(this, 'props.next.uid') : null,
        meta: _.get(this, 'props.data'),
      });

      if (_.get(this, 'container.current')) {
        this.container.current.scrollTo(0, 0);
      }
    }
  }

  date() {
    let date = _.get(this, 'props.data.data.date');
    if (date){
      date = date.split('-');
      date = [].concat(date[1], date[2], date[0]).join('-').toString();
    }
    return date;
  }

  snippet() {
    const snippet = _.get(this, 'props.data.data.snippet');
    return snippet ? snippet : _.get(this, 'props.data.data.body', []);
  }

  toggleDetail(hide) {
    document.querySelector('html').classList.add('no-scroll');
  }

  componentWillUnmount() {
    document.querySelector('html').classList.remove('no-scroll');
  }

  render() {
    const { uid, meta, title, baseURL, image, date, body, previous, next } = this.state;
    const url = baseURL + this.props.search;

    return (
      <div className="article_detail" key={uid}>
        {meta && <Metadata data={meta} title={title || 'News'} />}
        <div className="article_detail-content" ref={this.container}>
          <object>
            <NavLink to={url} className="button_close">
              <IconClose />
            </NavLink>
          </object>
          {image && <ResponsiveImage
            customClass="article_image"
            image={image}
          />}
          <div className="article_content">
            {title && <Heading2 text={title} customClass="margin-none" />}
            {date && <p className="body-small margin-bottom-small">{date}</p>}
            {body && <RichText text={body} />}

            <div className="article_detail-buttons margin-top-large">
              <object>
                {previous &&
                  <NavLink to={previous} className="button" rel="prev">
                    Previous
                  </NavLink>
                }
                {!previous && <span></span>}
              </object>
              <object>
                {next &&
                  <NavLink to={next} className="button" rel="next">
                    Next
                  </NavLink>
                }
                {!next && <span></span>}
              </object>
            </div>
          </div>
        </div>
        <NavLink to={url}>
          <div className="overlay" ></div>
        </NavLink>
      </div>
    )
  }
}