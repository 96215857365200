import React from 'react';
import _ from 'lodash';

import { Heading2 } from '../Headings.js';
import ResponsiveImage from '../responsive-image.js';
import RichText from '../rich-text.js';

export default class Item extends React.Component {
  image() {
    return _.get(this, 'props.data.data.image');
  }

  title() {
    return _.get(this, 'props.data.data.title[0].text');
  }

  body() {
    const snippet = _.get(this, 'props.data.data.snippet');
    return snippet ? snippet : _.get(this, 'props.data.data.body');
  }

  button() {
    const text = _.get(this, 'props.data.data.button_text[0].text', 'Read more');
    const uid = _.get(this, 'props.data.uid');

    if (uid && text) {
      return {
        text,
        url: '/news/' + _.get(this, 'props.data.uid'),
      }
    }

    return {};
  }

  render() {
    return (
      <div className="featured-news">
        {this.image() && <ResponsiveImage customClass="featured-item_image cover" image={this.image()} featured={true} />}
        <div className="featured-item_content">
          {this.title() && <Heading2 text={this.title()} />}
          {this.body() && <RichText text={this.body()}/>}
          {this.button().url && this.button().text && <a className="button" href={this.button().url}>{this.button().text}</a>}
        </div>
      </div>
    )
  }
}