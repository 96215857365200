import React from 'react';
import _ from 'lodash';

import Card from '../components/card';
import { Heading, Heading2 } from '../components/Headings.js';
import ResponsiveImage from '../components/responsive-image.js';
import { IconExternal } from '../components/Icons.js';
import Metadata from '../helpers/Metadata';
import SecondaryNav from '../components/SecondaryNav';
import { getPageData, getItemsData, getFeaturedData, getLatestData } from '../data/merch';
import { getArtistData, getArtistsData } from '../data/artists';

export default class Merch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      artists: [],
    };
  }

  componentDidMount() {
    this.getContent();
  }

  createSections(data) {
    const { types, latestTitle, links } = data;
    const sections = {};

    sections['latest'] = {
      key: 'latest',
      name: latestTitle,
      items: [],
    }

    types.forEach((type) => {
      const name = _.get(type, 'type_name[0].text');
      const link = _.find(links, { text: name });
      sections[name.toLowerCase()] = {
        key: name.toLowerCase(),
        name,
        items: [],
        url: link.url,
      }
    });

    return sections;
  }

  createSectionData(sections, items, type) {
    if (!_.isEmpty(items)) {
      // items.forEach((item) => {
      //   const { type } = item;
      //   if (sections[type] && sections[type].items.length < 4) {
      //     sections[type].items.push(item);
      //   }
      // });
      sections[type].items = items;
    }

    return sections;
  }

  async getContent() {
    const pageData = await getPageData();
    const artists = await this.getArtists();
    const sections = this.createSections(pageData);
    const latest = await getLatestData(getArtistData);
    const promises = Object.keys(sections).map((section) => getFeaturedData(artists, section));
    const results = await Promise.all(promises);

    this.createSectionData(sections, latest.items, 'latest');
    results.forEach((result) => {
      this.createSectionData(sections, result.items, result.type);
    });

    // const { items } = await getItemsData(artists, {
    //   sorts: {
    //     field: 'document.first_publication_date',
    //     direction: 'desc',
    //   }
    // });
    // const sections = this.createSectionData(pageData, items);

    this.setState(pageData);
    this.setState({ artists });
    this.setLinks(sections);

    Object.keys(sections).forEach((key) => {
      if (sections[key].items.length < 1) {
        delete sections[key];
      }
    });

    this.setState({ sections });
  }

  setLinks(links) {
    const navLinks = _.map(links, (link) => ({
      key: link.key,
      url: link.url,
      text: link.name,
    }))
    .filter((link, key) => link.key !== 'latest');

    this.setState({ navLinks });
  }

  async getArtists() {
    const artists = await getArtistsData();
    return artists.map((artist) => {
      return {
        ...artist,
        link: {url: this.composeLink(`artists/${_.get(artist, 'uid')}`)},
      }
    });
  }

  composeLink(link) {
  	const href = _.get(window, 'location.pathname');
  	const baseURL = _.split(href, '/')[1];
  	return `/${baseURL}/${link}`;
  }

  render() {
    const { artistTitle, artists, metadata, title, navLinks, image, storeLink, sections } = this.state;

    return (
      <section>
        {metadata && <Metadata title={title} data={metadata} />}
        {navLinks && <SecondaryNav links={navLinks} />}
        {image && storeLink &&
          <a href={storeLink} target="_blank">
            <ResponsiveImage
              image={image}
              customClass="margin-bottom-small"
            />
            <h3 className="margin-bottom-xlarge">{storeLinkText} <IconExternal size="small"/></h3>
          </a>
        }
        {image && !storeLink &&
          <ResponsiveImage
            image={image}
            customClass="margin-bottom-small"
          />
        }
        {title && <Heading text={title} customClass="padding-vertical-medium-l margin-bottom-medium" />}
        {sections && 
          Object.keys(sections).map((key) => {
            const section = sections[key];
            return <section key={key} className='padding-none'>
              {section.url
                ? <a href={this.composeLink(section.url)}>
                    <Heading2 text={section.name} customClass="padding-vertical-medium-l"/>
                  </a>
                : <Heading2 text={section.name} customClass="padding-vertical-medium-l"/>
              }
              <div className="artists grid-container wrap padding-top-small margin-bottom-medium break-column-s">
                {section.items.map((item, index) => (
                  <Card key={index} title={item.title} subtitle={item.artist} subtext={item.price} image={item.image} link={item.link} />
                ), this)}
              </div>
            </section>
          })
        }

        {artists.length > 0 &&
          <section className='padding-none'>
            {artistTitle && <Heading2 text={artistTitle} customClass="padding-vertical-medium-l" />}
            <div className="artists grid-container wrap padding-top-small margin-bottom-medium">
              {artists.map((item, index) => (
                <Card key={index} title={item.name} image={item.image} link={item.link} />
              ), this)}
            </div>
          </section>
        }
      </section>
    );
  }
}