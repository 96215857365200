import React from 'react';

import '../styles/icons.scss';
// import bandcamp from '../assets/images/bandcamp-button-bc-circle-black-256.png';

function Logo(props) {
  return (
    <svg viewBox="0 0 364 119" width="100%" height="100%" className="logo">
      <g>
        <g>
          <path d="M243,37c2.3-2.5,2.8-1.9,4-6c-6.8-0.1-8.6-1.3-13-3c-0.3-1.3-0.7-2.7-1-4v-1c4-0.7,8-1.3,12-2l4,12
            c1.3,0.3,2.7,0.7,4,1c0.7-6.3-1.6-9.6-3-16c4-1.2,3.8-1.3,6-4c-6.1-1.4-15.2,0.9-21,3h-1c0.4-7.8,3-12.7,9-15
            c-1.8-3.9-0.5-2.8-5-4c-8.6,7.5-13.6,19-9,34C233.5,34.9,235.2,36.9,243,37z M364,0c-8.2,1.8-11.2,1-18-1c0.3,1.7,0.7,3.3,1,5
            c-3.2,1.7-4.2,1.8-6,5c-0.7,1.3-1.3,2.7-2,4c3.3-1.3,6.7-2.7,10-4c2.9,6.8,5.8,12.7,6,22c9.8-3.4,3.1-17.5,0-23l8-4
            C363.3,2.7,363.7,1.3,364,0z M167,23c0.7,4.7,1.3,9.3,2,14c1.3,0.7,2.7,1.3,4,2h1c3.2-10.9,0.8-33-5-39c-1.8,4.2-2.2,10.2-2,17
            c-8.8,1.5-11.5-2-20-3v4C152.8,22.5,158,27,167,23z M272,30h1v-7c2.7-1,5.3-2,8-3c1.5,4.6,2.3,5.7,6,8c4.5-6-2.1-23.9-9-26
            c0.7,4.3,1.3,8.7,2,13c-2.3,0.7-4.7,1.3-7,2c-2-3.3-4-6.7-6-10c-1-0.3-2-0.7-3-1l-2,2c2,7,4,14,6,21C269.3,29.3,270.7,29.7,272,30
            z M324,10c2-0.7,4-1.3,6-2V4c-2.3-0.3-4.7-0.7-7-1c-2.3,2.3-4.7,4.7-7,7v1c3.6,4.6,8,6.9,12,11c-2.6,2-0.9,1-4,1
            c-1.7,2.1-1.6,2-2,6c3,0.7,6,1.3,9,2c1-1.7,2-3.3,3-5c0.3-2.7,0.7-5.3,1-8c-3.3-2-6.7-4-10-6L324,10z M299,10
            c-1.3,0.7-2.7,1.3-4,2v8c4.1,3.1,9.7,8.2,17,5c1-0.3,2-0.7,3-1c-0.5-4-0.3-3.9-2-6C310.4,13,305.7,10.9,299,10z M123,41
            c3.4-1.9,4.2-3.6,6-7v-1c-6.8,1-11.5,1.8-19,1c-0.7-2-1.3-4-2-6c3.3-1,6.7-2,10-3c-2.3-5.4-4.9-3.7-12-3v-1c5.3-3.9,9.5-1.5,11-10
            c-4.7,1.3-9.3,2.7-14,4l-2,2c-1.7,5.2,1.5,13.8,3,19C107.8,38.4,115.9,44.1,123,41z M147,39c1-2,2-4,3-6v-1c-4.3-0.3-8.7-0.7-13-1
            c-1-5-2-10-3-15c-2.8-1.8-2.5-2.3-7-3c1,7.3,2,14.7,3,22C134.5,38.3,138.2,39.4,147,39z M85,16h-6c1.7,4.7,3.3,9.3,5,14
            c-7-4.3-14-8.7-21-13h-1c0.3,8.3,0.7,16.7,1,25c1.3,0.7,2.7,1.3,4,2h1c0.3-4.7,0.7-9.3,1-14c8,3.5,14.2,9,25,10
            C92.9,29.3,87.8,24.8,85,16z M48,17h-6c-3.2,3.8-7.3,12-4,19c1.6,4.5,2.8,6.1,7,8c2.7,0.3,5.3,0.7,8,1c2-2.3,4-4.7,6-7v-9
            C56.4,22.5,51.8,22,48,17z M53,35c-0.7,1-1.3,2-2,3c-9.5-0.8-8.4-6.8-7-15l9,9V35z M4,20v-2H0v33c8.8,4.1,25.6-1.4,29-10v-1
            c-7.3,1.3-14.7,2.7-22,4C7.2,34.8,7.1,25.9,4,20z M275,78l-1-2c3.7-0.7,7.3-1.3,11-2v-3c-4.7-0.3-9.3-0.7-14-1c-4.1,3-5.3,2.2-6,9
            c5.7,2.7,11.3,5.3,17,8c-4,2.3-8,4.7-12,7v4c6.2,1.4,9.6-1.1,15-3c2.4-3.3,4.9-4.8,3-10C285.5,80.4,281.6,78.5,275,78z M229,72
            c-1,0.7-2,1.3-3,2c2.8,2.5,7.1,3.5,12,4c2,5,4,10,6,15v1c-5.5,2-10.1-1.9-17-4V80c-4.1-6.4-13.6-5.5-21-3l-3,3v3l1,1
            c4.8-1.4,9.9-4.7,16-2h2v1c-2,1.7-4,3.3-6,5c-1.3-1.7-2.7-3.3-4-5c-4.1,5.5,0,11.7,2,17l2,1h3c-0.3-2.3-0.7-4.7-1-7l1-1
            c15.2,4.4,24.5,7.7,41,2c2.5-3.6,3.6-3.6,4-10C256.2,76.9,245.3,71.5,229,72z M256,85l1,2l-4,4c-5.2-1.6-5.8-4.9-7-10
            C249.3,82.3,252.7,83.7,256,85z M106,94c-0.4-1.9,3.8-5.8,4-14h-1c-3.9-5.8-12.9-5.6-23-5c-3.8,2.6-5.6,3-8,7l1,2
            c3.3-1,6.7-2,10-3c2.7,11.7,5.3,23.3,8,35l3,3h1c1.9-6.9-0.2-14-1-21c7,4.8,13.2,10,18,17h4c0.3-1,0.7-2,1-3
            C118.5,103.8,112,100.4,106,94z M97,89c-0.7-2.7-1.3-5.3-2-8c2.3,0.3,4.7,0.7,7,1h2C102.7,86.9,101.9,87.7,97,89z M132,107
            c-0.3-2.7-0.7-5.3-1-8c3.3-0.7,6.7-1.3,10-2c0.7-1,1.3-2,2-3c-3.5-2.7-7.2-1.5-13-1l-1-1v-2c4.3-2,8.7-4,13-6v-3
            c-2.3-0.3-4.7-0.7-7-1c-5,2-10,4-15,6l-1,2c2.9,2.8,4,2.8,6,7l-3,3c2.9,3.8,4.7,11.4,9,14c6.2,2.7,13.2-0.4,18-2c1-1.7,2-3.3,3-5
            v-1c-5,1-10,2-15,3H132z M162,96c1-7.8,4.2-8.5,8-13c-2.1-1.7-2-1.6-6-2c-3.8,3.6-6.5,6-9,11c0.7,7.2,2.8,7.8,6,12
            c5.8,1.2,7.2,1.3,13,0l2-1C174.3,98.7,167.1,97.6,162,96z M186,84c-4.5,8.1-6.4,13.3,2,19c5.9,1.2,10.9,0.4,16-1l3-3v-5
            C201.5,88,197.9,83.9,186,84z M199,97c-7.2,1.3-10.3,0.4-10-6c2.7,0.7,5.3,1.3,8,2c0.7,1,1.3,2,2,3V97z"/>
        </g>
      </g>
    </svg>
  )
}

function IconHamburger() {
  return (
    <svg viewBox="0 0 50 21">
      <path d="M48.5,3h-47C0.7,3,0,2.3,0,1.5v0C0,0.7,0.7,0,1.5,0l47,0C49.3,0,50,0.7,50,1.5v0C50,2.3,49.3,3,48.5,3z"/>
      <path d="M48.5,21h-47C0.7,21,0,20.3,0,19.5v0C0,18.7,0.7,18,1.5,18h47c0.8,0,1.5,0.7,1.5,1.5v0C50,20.3,49.3,21,48.5,21z"/>
      <path d="M48.5,12h-47C0.7,12,0,11.3,0,10.5v0C0,9.7,0.7,9,1.5,9h47c0.8,0,1.5,0.7,1.5,1.5v0C50,11.3,49.3,12,48.5,12z"/>
    </svg>
  )
}

function IconClose(props) {
  return (
    <svg viewBox="0 0 37 38" className={"svg svg--"+props.size}>
      <path d="M34.1,36.7L0.8,3.4c-0.6-0.6-0.6-1.5,0-2.1l0,0c0.6-0.6,1.5-0.6,2.1,0l33.2,33.2c0.6,0.6,0.6,1.5,0,2.1l0,0
        C35.6,37.3,34.6,37.3,34.1,36.7z"/>
      <path d="M0.8,34.6L34.1,1.3c0.6-0.6,1.5-0.6,2.1,0v0c0.6,0.6,0.6,1.5,0,2.1L2.9,36.7c-0.6,0.6-1.5,0.6-2.1,0h0
        C0.2,36.1,0.2,35.1,0.8,34.6z"/>
    </svg>
  )
}

function IconExternal(props) {
  return (
    <svg viewBox="0 0 24 24" className={"external svg--"+props.size}>
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/>
    </svg>
  )
}

function IconGhost(props) {
  return (
    <svg viewBox="0 0 169 226" className={"ghost svg--"+props.size}>
      <clipPath id="ghost">
        <path d="M141,22C113-2,84,0,84,0h-1c0,0-27-3-55,21S0,77,0,77v138c0,0,10,10,22,10s18-6,18-6s5-6,13-6c10,0,11,5,17,9
  s14,4,14,4h1c0,0,8,1,14-3s8-9,17-9c8,0,13,6,13,6s6,6,18,6s22-10,22-10V78C169,78,169,46,141,22z"/>
      </clipPath>
    </svg>
  )
}


//------------ Social Icons
function IconFacebook(props) {
  return <svg className={"facebook svg svg--"+props.size} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 56.693 56.693" aria-labelledby="title">
    <title id="title">Facebook Icon</title>
    <path d="M40.43,21.739h-7.645v-5.014c0-1.883,1.248-2.322,2.127-2.322c0.877,0,5.395,0,5.395,0V6.125l-7.43-0.029 
    c-8.248,0-10.125,6.174-10.125,10.125v5.518h-4.77v8.53h4.77c0,10.947,0,24.137,0,24.137h10.033c0,0,0-13.32,0-24.137h6.77 
    L40.43,21.739z" />
  </svg>;
}

function IconTwitter(props) {
  return <svg className={"twitter svg svg--"+props.size} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 32 32" aria-labelledby="title">
    <title id="title">Twitter Icon</title>
    <path d="M31.993,6.077C30.816,6.6,29.552,6.953,28.223,7.11c1.355-0.812,2.396-2.098,2.887-3.63 
    c-1.269,0.751-2.673,1.299-4.168,1.592C25.744,3.797,24.038,3,22.149,3c-3.625,0-6.562,2.938-6.562,6.563 
    c0,0.514,0.057,1.016,0.169,1.496C10.301,10.785,5.465,8.172,2.227,4.201c-0.564,0.97-0.888,2.097-0.888,3.3 
    c0,2.278,1.159,4.286,2.919,5.464c-1.075-0.035-2.087-0.329-2.972-0.821c-0.001,0.027-0.001,0.056-0.001,0.082 
    c0,3.181,2.262,5.834,5.265,6.437c-0.55,0.149-1.13,0.23-1.729,0.23c-0.424,0-0.834-0.041-1.234-0.117 
    c0.834,2.606,3.259,4.504,6.13,4.558c-2.245,1.76-5.075,2.811-8.15,2.811c-0.53,0-1.053-0.031-1.566-0.092 
    C2.905,27.913,6.355,29,10.062,29c12.072,0,18.675-10.001,18.675-18.675c0-0.284-0.008-0.568-0.02-0.85 
    C30,8.55,31.112,7.395,31.993,6.077z"/>
  </svg>;
}

function IconInstagram(props) {
  return <svg fill="currentColor" className={"instagram svg svg--"+props.size} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 56.7 56.7" aria-labelledby="title">
    <title id="title">Instagram Icon</title>
    <g><path d="M28.2,16.7c-7,0-12.8,5.7-12.8,12.8s5.7,12.8,12.8,12.8S41,36.5,41,29.5S35.2,16.7,28.2,16.7z M28.2,37.7   c-4.5,0-8.2-3.7-8.2-8.2s3.7-8.2,8.2-8.2s8.2,3.7,8.2,8.2S32.7,37.7,28.2,37.7z"/><circle cx="41.5" cy="16.4" r="2.9"/><path d="M49,8.9c-2.6-2.7-6.3-4.1-10.5-4.1H17.9c-8.7,0-14.5,5.8-14.5,14.5v20.5c0,4.3,1.4,8,4.2,10.7c2.7,2.6,6.3,3.9,10.4,3.9   h20.4c4.3,0,7.9-1.4,10.5-3.9c2.7-2.6,4.1-6.3,4.1-10.6V19.3C53,15.1,51.6,11.5,49,8.9z M48.4,39.9c0,3.1-0.9,5.6-2.7,7.3   c-1.8,1.7-4.3,2.6-7.3,2.6H18c-3,0-5.5-0.9-7.3-2.6C8.9,45.4,8,42.9,8,39.8V19.3c0-3,0.9-5.5,2.7-7.3c1.7-1.7,4.3-2.6,7.3-2.6h20.6   c3,0,5.5,0.9,7.3,2.7c1.7,1.8,2.7,4.3,2.7,7.2V39.9z"/></g>
  </svg>;
}

function IconBandcamp(props) {
  return (
    <svg
      className={"bandcamp svg svg--"+props.size}
      viewBox="0 0 682.66669 682.66669"
      height="100%"
      width="100%"
      id="svg2"
      version="1.1"><clipPath
       id="clipPath20"
       clipPathUnits="userSpaceOnUse"><path
         id="path18"
         d="M 2560,0 C 3973.85,0 5120,1146.15 5120,2560 5120,3973.85 3973.85,5120 2560,5120 1146.15,5120 0,3973.85 0,2560 0,1146.15 1146.15,0 2560,0 Z" /></clipPath><clipPath
       id="clipPath26"
       clipPathUnits="userSpaceOnUse"><path
         id="path24"
         d="M 0,0 H 5120 V 5120 H 0 Z" /></clipPath><clipPath
       id="clipPath36"
       clipPathUnits="userSpaceOnUse"><path
         id="path34"
         d="m 3248.57,2735.96 c -183.49,0 -277.25,-144.42 -277.25,-362 0,-205.66 101.38,-360.04 277.25,-360.04 198.89,0 273.45,182.02 273.45,360.04 -0.14,185.9 -93.84,362 -273.51,362 M 2761,3275.92 h 217.97 v -522.15 h 3.85 c 59.28,98.87 183.55,160.21 294.46,160.21 311.63,0 462.74,-245.35 462.74,-544.05 0,-274.84 -133.84,-533.97 -426.43,-533.97 -133.85,0 -277.25,33.54 -342.29,168.09 h -3.78 v -140.51 h -206.53 v 1412.35 l 0.01,0.03" /></clipPath><clipPath
       id="clipPath42"
       clipPathUnits="userSpaceOnUse"><path
         id="path40"
         d="M 0,0 H 5120 V 5120 H 0 Z" /></clipPath><clipPath
       id="clipPath52"
       clipPathUnits="userSpaceOnUse"><path
         id="path50"
         d="m 4543.89,2544.02 c -17.22,124.62 -105.15,191.92 -225.61,191.92 -112.87,0 -271.54,-61.35 -271.54,-371.91 0,-170.13 72.64,-350.15 261.97,-350.15 126.18,0 214.11,87.14 235.18,233.51 h 217.97 c -40.16,-265.06 -198.84,-411.43 -453.15,-411.43 -309.78,0 -479.95,227.42 -479.95,528.07 0,308.56 162.53,549.89 487.6,549.89 229.44,0 424.54,-118.68 445.5,-369.91 h -217.61 l -0.36,0.01" /></clipPath><clipPath
       id="clipPath58"
       clipPathUnits="userSpaceOnUse"><path
         id="path56"
         d="M 0,0 H 5120 V 5120 H 0 Z" /></clipPath><clipPath
       id="clipPath68"
       clipPathUnits="userSpaceOnUse"><path
         id="path66"
         d="M 1998.95,1866.62 H 364.078 l 763.462,1409.3 h 1634.88 l -763.47,-1409.3" /></clipPath><clipPath
       id="clipPath74"
       clipPathUnits="userSpaceOnUse"><path
         id="path72"
         d="M 0,0 H 5120 V 5120 H 0 Z" /></clipPath><g
     transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)"
     id="g10"><g
       transform="scale(0.1)"
       id="g12"><g
         id="g14"><g
           clipPath="url(#clipPath20)"
           id="g16"><g
             clipPath="url(#clipPath26)"
             id="g22"><path
               id="path28"
               style={{fillOpacity:1, fillRule: 'nonzero', stroke:'none'}}
               d="M 0,0 H 5170 V 5170 H 0 Z" /></g></g></g><g
         id="g30"><g
           clipPath="url(#clipPath36)"
           id="g32"><g
             clipPath="url(#clipPath42)"
             id="g38"><path
               id="path44"
               style={{fill:'currentColor', fillOpacity:1, fillRule: 'nonzero', stroke:'none'}}
               d="M 2710.99,1785.96 H 3790.02 V 3325.92 H 2710.99 Z" /></g></g></g><g
         id="g46"><g
           clipPath="url(#clipPath52)"
           id="g48"><g
             clipPath="url(#clipPath58)"
             id="g54"><path
               id="path60"
               style={{fill:'currentColor', fillOpacity:1, fillRule: 'nonzero', stroke:'none'}}
               d="m 3778.76,1785.96 h 1033.1 v 1177.96 h -1033.1 z" /></g></g></g><g
         id="g62"><g
           clipPath="url(#clipPath68)"
           id="g64"><g
             clipPath="url(#clipPath74)"
             id="g70"><path
               id="path76"
               style={{fill:'currentColor', fillOpacity:1, fillRule: 'nonzero', stroke:'none'}}
               d="m 314.078,1816.62 h 2498.34 v 1509.3 H 314.078 Z" /></g></g></g></g></g></svg>
  )
  // return <img src={bandcamp} alt="Lonely Ghost Records Bandcamp" className={"bandcamp svg--"+props.size} />;
}

function IconReddit(props) {
  return <svg className={"reddit svg svg--"+props.size} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" aria-labelledby="title">
    <title id="title">Reddit Icon</title>
    <path d="M14.238 15.348c.085.084.085.221 0 .306-.465.462-1.194.687-2.231.687l-.008-.002-.008.002c-1.036 0-1.766-.225-2.231-.688-.085-.084-.085-.221 0-.305.084-.084.222-.084.307 0 .379.377 1.008.561 1.924.561l.008.002.008-.002c.915 0 1.544-.184 1.924-.561.085-.084.223-.084.307 0zm-3.44-2.418c0-.507-.414-.919-.922-.919-.509 0-.923.412-.923.919 0 .506.414.918.923.918.508.001.922-.411.922-.918zm13.202-.93c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-.129c0-.851-.695-1.543-1.55-1.543-.417 0-.795.167-1.074.435-1.056-.695-2.485-1.137-4.066-1.194l.865-2.724 2.343.549-.003.034c0 .696.569 1.262 1.268 1.262.699 0 1.267-.566 1.267-1.262s-.568-1.262-1.267-1.262c-.537 0-.994.335-1.179.804l-2.525-.592c-.11-.027-.223.037-.257.145l-.965 3.038c-1.656.02-3.155.466-4.258 1.181-.277-.255-.644-.415-1.05-.415-.854.001-1.549.693-1.549 1.544 0 .566.311 1.056.768 1.325-.03.164-.05.331-.05.5 0 2.281 2.805 4.137 6.253 4.137s6.253-1.856 6.253-4.137c0-.16-.017-.317-.044-.472.486-.261.82-.766.82-1.353zm-4.872.141c-.509 0-.922.412-.922.919 0 .506.414.918.922.918s.922-.412.922-.918c0-.507-.413-.919-.922-.919z"/>
  </svg>;
}

export {
  Logo,
  IconFacebook,
  IconTwitter,
  IconInstagram,
  IconHamburger,
  IconClose,
  IconBandcamp,
  IconReddit,
  IconExternal,
  IconGhost
}