import React from 'react';

function Heading(props) {
  const type = 'heading';
  const c = props.customClass ? props.customClass + ' ' + type : type;
  return (
    <h1 className={c}>
      {props.text}
    </h1>
  );
}

function Heading2(props) {
  const type = 'heading2';
  const c = props.customClass ? props.customClass + ' ' + type : type;
  const content = props.text || props.children;
  return (
    <h2 className={c}>
      {content}
    </h2>
  );
}

function Heading3(props) {
  const type = 'heading3';
  const c = props.customClass ? props.customClass + ' ' + type : type;
  return (
    <h3 className={c}>
      {props.text}
    </h3>
  );
}

function Heading4(props) {
  const type = 'heading4';
  const c = props.customClass ? props.customClass + ' ' + type : type;
  return (
    <h4 className={c}>
      {props.text}
    </h4>
  );
}

function Heading5(props) {
  const type = 'heading5';
  const c = props.customClass ? props.customClass + ' ' + type : type;
  return (
    <h4 className={c}>
      {props.text}
    </h4>
  );
}

function Heading6(props) {
  const type = 'heading6';
  const c = props.customClass ? props.customClass + ' ' + type : type;
  return (
    <h4 className={c}>
      {props.text}
    </h4>
  );
}

export {
  Heading,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6
}