import React from 'react';
import { Helmet } from 'react-helmet';
import _ from 'lodash';

import globalState from '../global-state.js';
import metadata from '../metadata_defaults';

function getType(data) {
  return _.get(data, 'data.type') === 'article' ? 'article' : 'website';
}

function getURL() {
  return `${window.location.href}`;
}

function getImage(data) {
  const base = _.get(data, 'data', data);
  return _.get(base, 'image.thumbnail.url')
      || _.get(base, 'image.url')
      || 'https://images.prismic.io/lonelyghost/1436ca6d-752c-4ee2-9e36-db28be739776_ghost.png?auto=compress,format';
}

function getDesc(data) {
  const base = _.get(data, 'data', data);
  const desc = _.get(base, 'description_metadata[0].text');
  if (desc) {
    return desc;
  } else {
    const homeData = globalState.get('home');
    return _.get(homeData, 'metadata.data.description_metadata[0].text', metadata.desc);
  }
}

export default class Metadata extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const data = _.get(this, 'props.data');
    const title = _.get(this, 'props.title');

    const desc = getDesc(data);
    const image = getImage(data);
    const url = getURL();
    const type = getType(data);
    // const amp = getAMPData(data, type);

    this.setState({
      title: (title ? `${title} - ` : '') + metadata.title,
      desc,
      image,
      url,
      type,
      // amp,
    });
  }

  render() {
    const { title, desc, type, image, url } = this.state;
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />

        <meta property="og:title" content={title || 'Lonely Ghost Records'} />
        <meta property="og:description" content={desc} />
        <meta property="og:type" content={type} />
        {image && <meta property="og:image" content={image} />}
        {url && <meta property="og:url" content={url} />}

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={title || 'Lonely Ghost Records'} />
        <meta property="twitter:description" content={desc} />
        {image && <meta property="twitter:image" content={image} />}
        {url && <meta property="twitter:url" content={url} />}
      </Helmet>
    );
  }
}

// function getAMPData(data, type) {
//   console.log('getting data', data)
//   const url = getURL();
//   return type === 'article' ? `{
//       "@context": "https://schema.org",
//       "@type": "NewsArticle",
//       "mainEntityOfPage": {
//         "@type": "WebPage",
//         "@id": "${url}"
//       },
//       "headline": "${_.get(data, 'data.title[0].text')}",
//       "image": [
//         "${_.get(data, 'data.image.thumbnail.url')}"
//        ],
//       "datePublished": "${_.get(data, 'first_publication_date')}",
//       "dateModified": "${_.get(data, 'last_publication_date')}",
//       "author": {
//         "@type": "Organization",
//         "name": "Lonely Ghost Records"
//       },
//        "publisher": {
//         "@type": "Organization",
//         "name": "Lonely Ghost Records",
//         "logo": {
//           "@type": "ImageObject",
//           "url": "/static/media/ghost.4f593397.png"
//         }
//       }
//     }` : null;
// }
