import React from 'react';
import _ from 'lodash';

import ResponsiveImage from '../components/responsive-image.js';
import RichText from '../components/rich-text.js';
import { Heading, Heading2 } from '../components/Headings.js';
import {default as ReleaseItem} from '../components/Features/item.js';
import Breadcrumbs from '../components/Breadcrumbs';
import globalState from '../global-state.js';
import Metadata from '../helpers/Metadata';
import { client } from '../prismic-configuration';

export default class Artist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseURL: '',
      url: '',
      uid: '',
      name: null,
      mobileImage: null,
      desktopImage: null,
      hidden: true,
      releases: [],
    };
  }

  componentDidMount() {
    const uid = null;
    const has = globalState.get(`artist:${uid}`);

    if (has) {
      this.setContent(has);
    } else {
      this.getContent();
    }
  }

  getContent(prevProps) {
    const self = this;
    const id = _.get(this, 'props.params.match.params.id');
    client.getByUID('artist', id)
      .then(data => {
        const res = _.get(data, 'data');
        if (res && Object.keys(res).length > -1) {
          const uid = _.get(data, 'uid');
          const obj = {
            uid,
            name: _.get(res, 'name[0].text'),
            image: _.get(res, 'image'),
            thumbnailImage: _.get(res, 'image.thumbnail.url'),
            bio: _.get(res, 'bio'),
            socialLinks: self.mapLinks(_.get(res, 'social_links'), uid),
            metadata: res,
          }

          self.setContent(obj);
          globalState.set(`artist:${uid}`, obj);
          return res;
        }
      })
      .then((data) => {
        const releases = _.get(data, 'releases');
        if (releases) {
          return Promise.all(_.map(releases, (release) => {
            const id = _.get(release, 'release.id');
            return client.getByID(id)
          }))
        }
      })
      .then((data) => {
        if (data) {
          self.setState({
            releases: data,
          });
        }
      })
      .catch(function(err) {
        console.log('Artist error: ', err);
      });
  }

  mapLinks(links, uid) {
    return _.map(links, (link) => {
      if (_.get(link, 'link.uid') === 'news') {
        link.link = {
          url: `/news?filter=artist:${uid}`
        }
      }

      return link;
    });
  }

  breadcrumbs() {
    return [
      {
        url: '/artists',
        text: 'Artists',
      },
      {
        text: this.state.name,
      },
    ]
  }

  setContent(state) {
    this.setState(state);
  }

  render() {
    const { metadata, name, image, bio, socialLinks, releases } = this.state;
    return (
      <section className="artist_detail">
        {metadata && <Metadata title={name} data={metadata} />}

        <Breadcrumbs pages={this.breadcrumbs()} customClass="padding-vertical-medium-l" />

        <div className="artist_detail-content">
          {image &&
            <div className="artist_image grid-container">
              <div className="grid-3-m padding-vertical-small-m">
                <ResponsiveImage image={image} />
              </div>
              <div className="artist_content grid-70-large padding-vertical-small-m">
                {name && <Heading text={name} />}
                {bio && <RichText text={bio} />}
                <div className="margin-top-medium artist_social">
                {socialLinks.map(function(link, index) {
                  if (link.icon.url) {
                    return <a href={link.link.url} target="blank" key={index}>
                      {link.icon.url && <img src={link.icon.url} alt={link.icon.alt} className="margin-right-xsmall" />}
                    </a>
                  } else {
                    return <a href={_.get(link, 'link.url')} target={_.get(link, 'link.target') || '_self'} key={index} className="button margin-right-xsmall">
                      {link.label}
                    </a>
                  }
                })}
                </div>
              </div>
            </div>
          }
        </div>
        {releases.length > 0 && <div className="artist_albums margin-top-medium">
          <Heading2 text="Releases" customClass="margin-bottom-medium" />
          <div className="grid-container wrap break-out-small">
            {releases.map((release, index) => {
              return <div className="grid-2 grid-4-m loaded" key={index}>
                <ReleaseItem data={release} />
              </div>
            })}
          </div>
        </div>}
      </section>
    );
  }
}
