import React from 'react';
import _ from 'lodash';

import Artist from '../components/artist.js';
import { Heading } from '../components/Headings.js';
import ContactForm from '../components/contact-form.js';
import globalState from '../global-state.js';
import Metadata from '../helpers/Metadata';
import { client } from '../prismic-configuration';

export default class Artists extends React.Component {
  constructor(props) {
    super(props);
    const params = _.get(props, 'params.match');
    this.state = {
      artists: [],
      params,
    };
  }

  componentDidMount() {
    const has = globalState.get('artists');
    window.scrollTo(0, 0);
    if (has) {
      this.setContent(has);
    } else {
      this.getContent();
    }
  }

  setContent(state) {
    this.setState(state);
  }

  getContent() {
    const self = this;
    client.getByType('artist_page')
      .then(data => {
        const res = _.get(data, 'results[0].data');
        if (res && Object.keys(res).length > -1) {
          const obj = {
            title: _.get(res, 'title[0].text'),
            artists: _.get(res, 'artists'),
            metadata: {
              data: { description_metadata: _.get(res, 'description_metadata')},
            },
          }

          self.setState(obj);
          globalState.set('artist', obj);
        }
      })
      .catch(function(err) {
        console.log(err);
      });
  }

  render() {
    return (
      <section>
        {this.state.metadata && <Metadata title="Artists" data={this.state.metadata} />}
        {this.state.title && <Heading text={this.state.title} customClass="padding-vertical-medium-l margin-bottom-medium" />}
        {this.state.artists && this.state.artists.length > 0 &&
          <div className="artists grid-container wrap margin-top-small margin-bottom-medium margin-bottom-xlarge-l padding-vertical-small-l break-column-s">
            {this.state.artists.map(function(artist, index) {
              return <Artist info={artist.artist} key={index} params={this.state.params} />
            }, this)}
          </div>
        }

        <ContactForm />
      </section>
    );
  }
}