import _ from 'lodash';
import * as prismic from '@prismicio/client';

import { client } from '../prismic-configuration';
import { getArtistsData } from './artists';

export async function getPageData() {
  const data = await client.getByType('merch_page');
  const res = _.get(data, 'results[0].data');
  const types = _.get(res, 'types');
  const links = _.map(types, (type) => {
    const name = _.get(type, 'type_name[0].text');
    return {
      url: encodeURIComponent(name.toLowerCase()),
      text: name,
    };
  });

  return {
    title: _.get(res, 'title[0].text'),
    artistTitle: _.get(res, 'artist_title[0].text'),
    storeLink: _.get(res, 'store_link.url'),
    storeLinkText: _.get(res, 'link_text[0].text'),
    latestTitle: _.get(res, 'latest_title[0].text'),
    image: _.get(res, 'image'),
    subtitle: _.get(res, 'subtitle[0].text'),
    types,
    links,
    metadata: {
      data: { description_metadata: _.get(res, 'description_metadata')},
    },
  };
}

async function createData(featuredData, data, artistsData, type) {
  const items = [];
  if (_.isEmpty(artistsData)) {
    artistsData = await getArtistsData();
  }

  const results = featuredData.results.concat(data.results).splice(0, 4);

  console.log('created', type, results)

  if (results) {
    results.forEach((item) => {
      const artistId = _.get(item, 'data.artist.id');
      const artistData = _.isArray(artistsData) ? _.find(artistsData, { id: artistId }) : artistsData;

      if (!items.find((i) => i.title ===  _.get(item, 'data.title[0].text'))) {
        items.push({
          title: _.get(item, 'data.title[0].text'),
          artistId: _.get(artistData, 'id'),
          artist: _.get(artistData, 'name'),
          image: _.get(item, 'data.image'),
          link: _.get(item, 'data.link'),
          price: `$${(_.get(item, 'data.price') || 0).toFixed(2)}`,
          type: _.get(item, 'tags[0]', '').toLowerCase(),
          tags: _.get(item, 'tags'),
        });
      }
    });
    return {
      type,
      items,
    };
  }

  return {};
}

export async function getLatestData(artistsData) {
  const featuredData = await client.getByType('merch_item', {
    filters: [
      prismic.filter.at('document.tags', ['latest']),
    ],
    orderings: [
      {
        field: 'document.first_publication_date',
        direction: 'desc',
      },
    ],
    pageSize: 4,
  });
  const data = await client.getByType('merch_item', {
    filters: [
      prismic.filter.not('document.tags', ['latest']),
    ],
    orderings: [
      {
        field: 'document.first_publication_date',
        direction: 'desc',
      },
    ],
    pageSize: 4,
  });

  return createData(featuredData, data, artistsData, 'latest');
}

export async function getFeaturedData(artistsData, type) {
  const featuredData = await client.getByType('merch_item', {
    filters: [
      prismic.filter.at('document.tags', [type]),
      prismic.filter.at('document.tags', ['featured']),
      prismic.filter.not('document.tags', ['latest']),
    ],
    orderings: [
      {
        field: 'document.first_publication_date',
        direction: 'desc',
      },
    ],
  });
  const data = await client.getByType('merch_item', {
    filters: [
      prismic.filter.at('document.tags', [type]),
      prismic.filter.not('document.tags', ['featured']),
      prismic.filter.not('document.tags', ['latest']),
    ],
    orderings: [
      {
        field: 'document.first_publication_date',
        direction: 'desc',
      },
    ],
  });
  
  return createData(featuredData, data, artistsData, type);
}

export async function getItemsData(artistsData, config = {}) {
  const { filters, sorts, pageSize, page } = config;
  const items = [];

  const data = await client.getByType('merch_item', {
    filters,
    orderings: sorts,
    pageSize,
    page,
  });

  if (_.isEmpty(artistsData)) {
    artistsData = await getArtistsData();
  }

  if (data.results) {
    data.results.forEach((item) => {
      const artistId = _.get(item, 'data.artist.id');
      const artistData = _.isArray(artistsData) ? _.find(artistsData, { id: artistId }) : artistsData;
      items.push({
        title: _.get(item, 'data.title[0].text'),
        artistId: _.get(artistData, 'id'),
        artist: _.get(artistData, 'name'),
        image: _.get(item, 'data.image'),
        link: _.get(item, 'data.link'),
        price: `$${(_.get(item, 'data.price') || 0).toFixed(2)}`,
        type: _.get(item, 'tags[0]', '').toLowerCase(),
        tags: _.get(item, 'tags'),
      });
    });
    return {
      totalPages: _.get(data, 'total_pages', 1),
      totalResults: _.get(data, 'total_results_size', 0),
      items,
    };
  }

  return {};
}