import React from 'react';

import '../styles/breadcrumbs.scss';

export default class Breadcrumbs extends React.Component {
	render() {
		const { pages, customClass } = this.props;

		return (
			<div className={'breadcrumbs ' + customClass}>
				{pages && pages.length > 0 && pages.map(function(page, index) {
					return <div key={index}>
            {page.url && index < pages.length - 1
              ? <a href={page.url}>{page.text}</a>
              : <span>{page.text}</span>
            }
						{index < pages.length - 1 && <span className="breadcrumbs_separator"></span>}
					</div>
				}, this)}
			</div>
		)
	}
}