import React from 'react';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';

import '../styles/secondary-nav.scss';

export default class SecondaryNav extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
      links: _.map(props.links, (link) => ({
        url: this.composeLink(link.url),
        text: link.text,
      })),
    };
    this.setHeight();
  }

  composeLink(link) {
  	const href = _.get(window, 'location.pathname');
  	const baseURL = _.split(href, '/')[1];
  	return `/${baseURL}/${link}`;
  }

  show() {
    const nav = document.querySelector('.navigation');
    const filter = document.querySelector('.secondary-nav');
    const placeholder = document.querySelector('.secondary-nav_placeholder');
    if (!filter || !placeholder) return;
    const navHeight = nav.offsetHeight;
    const top = placeholder.offsetTop;
    const passed = window.scrollY >= (top - navHeight);
    return passed;
  }

  setHeight() {
    const self = this;
    setTimeout(() => {
      const filter = document.querySelector('.secondary-nav');
      if (!filter) return;
      self.setState({height: filter.offsetHeight});
    });
  }

  render() {
  	const { links, direction } = this.state;
    const { children } = this.props;
  	return (
  		<div style={{position: 'relative'}}>
	  		<div className={this.show() ? "secondary-nav float" : "secondary-nav"}>
	  			<div className="secondary-nav_inner" style={{ justifyContent: direction === 'right' ? 'flex-end' : '' }}>
		  			{links.length > 0 && links.map((link, index) => {
		  				return <NavLink to={link.url} className="secondary-nav_link" key={index}>{link.text}</NavLink>
		  			}, this)}
	  			</div>

          {children}
	  		</div>
	  		<div className="secondary-nav_placeholder" style={{height: this.state.height}} />
	  	</div>
  	)
  }
}
