import React from 'react';
import _ from 'lodash';

import ResponsiveImage from '../responsive-image.js';
import RichText from '../rich-text.js';
import { Heading } from '../Headings';

export default class HeroItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.divClass = this.divClass.bind(this);
  }

  componentDidMount() {
    const content = _.get(this, 'props.data.data', []);
    if (content) {
      this.setContent();
    }
  }

  componentDidUpdate(prevProps) {
    const props = this.props;
    if (props !== prevProps) {
      this.setContent();
    }
  }

  setContent() {
    const base = _.get(this, 'props.data.data') || _.get(this, 'props.data');
    this.setState({
      image: _.get(base, 'image'),
      title: _.get(base, 'title') || _.get(base, 'hero_title', []),
      body: _.get(base, 'body') || _.get(base, 'hero_body', []), 
      button: _.get(base, 'button') || _.get(base, 'hero_button'),
      alignment: _.get(base, 'alignment'),
      colorScheme: _.get(base, 'color_scheme'),
    });
  }

  divClass() {
    const theme = this.state.colorScheme;
    const alignment = this.state.alignment;
    const themes = {
      'light': 'light',
      'dark': 'dark',
      'extra-light': 'extra light',
      'extra-dark': 'extra dark'
    }
    const alignments = {
      left: 'left',
      right: 'right',
      center: 'center'
    }

    const validTheme = themes[theme] ? themes[theme] : themes.light;
    const validAlignment = alignments[alignment] ? alignments[alignment] : alignments.left;
    let c = 'hero-item';
    c += ' ' + validTheme;
    c += ' ' + validAlignment;
    return c;
  }

  render() {
    const { image, title, body, button } = this.state;
    const titleIsRich = _.get(title, '[0].text');
    return (
      <div className={this.divClass()}>
        {image && <ResponsiveImage 
          customClass="hero-item_image cover"
          image={image}
          background={true}
        />}
        <div className="overlay"></div>
        <div className="hero-item_content">
          {title && titleIsRich && <RichText text={title} />}
          {title && !titleIsRich && <Heading text={title} />}
          {body && <RichText text={body} customClass="paragraph" />}
          {button && button.link && <a href={button.link} className="button">{button.text}</a>}
          {button && !button.link && <RichText text={button} customClass="button" />}
        </div>
      </div>
    );
  }
}
