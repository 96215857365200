import React from 'react';
import * as prismic from '@prismicio/client';
import _ from 'lodash';

export default class RichText extends React.Component {
  htmlSerializer(type, element, content, children) {
    switch(type) {
      case prismic.Element.heading1: return `<h1 class="heading1">${children}</h1>`;
      case prismic.Element.heading2: return `<h1 class="heading2">${children}</h2>`;
      case prismic.Element.heading3: return `<h1 class="heading3">${children}</h3>`;
      case prismic.Element.heading4: return `<h1 class="heading4">${children}</h4>`;
      case prismic.Element.heading5: return `<h1 class="heading5">${children}</h5>`;
      case prismic.Element.heading6: return `<h1 class="heading6">${children}</h6>`;
      case prismic.Element.hyperlink:
        let target = element.data.target ? 'target="' + element.data.target + '" rel="noopener"' : '';
        let linkUrl;
        if (_.get(element, 'data.link_type') === 'Web') {
          linkUrl = prismic.asLink(element.data, this.props.linkResolver);
        } else if (_.get(element, 'data.uid')) {
          let type = _.get(element, 'data.type');
          if (type === 'artist') {
            linkUrl = '/artists/' + _.get(element, 'data.uid');
          } else if (type === 'article') {
            linkUrl = '/news/' + _.get(element, 'data.uid');
          } else {
            linkUrl = _.get(element, 'data.uid');
          }
        }
        return '<a ' + target + ' href="' + linkUrl + '">' + content + '</a>';
      case prismic.Element.image: 
        return `<img src="${element.url}" alt="${element.alt}">`;
      default: 
        return null;
    }
  }

  body() {
    const body = _.get(this, 'props.text', []);
    const html = prismic.asHTML(body, { linkResolver: this.props.linkResolver, serializer: this.htmlSerializer.bind(this) })
    return {__html: html};
  }

  render() {
    return (
      <span className={this.props.customClass} dangerouslySetInnerHTML={this.body()}></span>
    )
  }
}