import React from 'react';
import _ from 'lodash';

import globalState from '../../global-state.js';
import { client } from '../../prismic-configuration';
import BlogFull from './BlogFull';
import BlogLazyWidget from './BlogLazyWidget';
import { Heading2 } from '../Headings';

function getType(tags) {
	const isMusic = _.indexOf(tags, 'playlist') > -1;
	const isVideo = _.indexOf(tags, 'video') > -1;
	const isInsta = _.indexOf(tags, 'instagram') > -1;

	return isMusic ? 'playlist' :
				 isVideo ? 'video' :
				 isInsta ? 'instagram' :
				 'blog';
}

function formatDate(date) {
  if (date) {
    date = date.split('-');
    date = [].concat(date[1], date[2], date[0]).join('-').toString();
  }
  return date;
}

export default class BlogItem extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
    	id: null,
      title: null,
      body: [],
      error: false,
    };
  }

  componentDidMount() {
  	const id = _.get(this, 'props.uid');
    const has = globalState.get(`blog:${id}`);

    if (has) {
      this.setContent(has);
    } else {
      this.getContent();
    }
  }

  getContent() {
    const self = this;
    const uid = _.get(this, 'props.uid');
  	client.getByUID('blog_post', uid)
      .then(data => {
        const body = _.get(data, 'data');
        if (body) {
          self.setContent(data);
        } else {
        	self.setState({ error: true });
        }
      });
  }

  parseData(data) {
  	const body = _.get(data, 'data');
  	const contentImage = _.find(body.body, {slice_type: 'feature_image'});
  	const image = contentImage ? _.get(contentImage, 'primary.image') : _.get(body, 'body[0].primary.link.thumbnail_url');
  	const href = _.get(window, 'location.pathname');
  	const baseURL = _.split(href, '/')[1];
  	const url = `/${baseURL}/${_.get(body, 'provider.uid')}/${_.get(data, 'uid')}`;
  	const related = _.get(body, 'related');
  	// console.log('parseData', url, data)
  	return {
    	id: _.get(data, 'id'),
    	url,
      title: _.get(body, 'title'),
      provider: _.get(body, 'provider'),
      tags: _.get(data, 'tags'),
      type: getType(_.get(data, 'tags')),
      date: formatDate(_.get(body, 'date')),
      image,
      primary: _.get(body, 'body[0].primary'),
      body: _.get(body, 'body'),
      description: _.get(body, 'description'),
      author: _.get(body, 'author'),
      socialShare: _.get(body, 'social_share'),
      relatedBlogs: _.get(related, 'length') && _.get(related, '[0].related_blog.id') ? related : [],
      imageSize: _.get(contentImage, 'primary.size'),
      featured: _.get(body, 'embedded', false),
      metadata: {
      	description_metadata: _.get(body, 'description'),
      }
    }
  }

  setContent(data) {
  	const state = this.parseData(data);
    this.setState(state);
  }

	render() {
		const { id, type, error } = this.state;
		if (this.props.type === 'widget' && id) {
			return <BlogLazyWidget slice={type} isWidget={true} data={this.state} />
		} else if (id) {
			return <BlogFull data={this.state} parseData={this.parseData} />
		} else if (error) {
			return <div className="padding-vertical-medium-l">
				<Heading2 text="An error has occurred, please try again later" />
			</div>
		} else {
			return <div />
		}
  }
}