import React from 'react';
import _ from 'lodash';

import { client } from '../../prismic-configuration';
import { lazyFetch } from '../../helpers/utility';
import { Heading } from '../Headings.js';
import FeaturedNewsItem from './FeaturedNewsItem';

import '../../styles/featured-news.scss';

export default class FeaturedNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      features: [],
    };
    this.container = React.createRef();
    this._getContent = this.getContent.bind(this);
  }

  componentDidMount() {
    this.container.current.addEventListener('lazy-load', this._getContent);
    lazyFetch(this.container.current);
  }

  componentWillUnmount() {
    this.container.current.removeEventListener('lazy-load', this._getContent);
  }

  getContent() {
    this.container.current.removeEventListener('lazy-load', this._getContent);
    this.setState({loaded: true});
    const self = this;
    if (this.props.data) {
      const sliced = this.props.data.slice(0, 2);
      const queries = _.map(sliced, function(value) {
        const id = _.get(value, 'news.id');
        return client.getByID(id)
      });

      Promise.all(queries)
        .then(function(data) {
          if (data && data.length > 0) {
            const obj = {features: data};
            self.setContent(obj);
          }
        })
        .catch(function(err) {
          console.log('error', err);
        });
    }
  }

  setContent(state) {
    state.loaded = true;
    this.setState(state, () => {
      this.props.childMounted('FeaturedNews');
    });
  }

  render() {
    if (this.props.data.length > 0) {
     return (
        <section className={this.state.loaded ? "loaded feature-container_news margin-bottom-medium" : "feature-container_news margin-bottom-medium"} ref={this.container}>
          {this.state.features.length > 0 && <Heading type="heading" text={this.props.title} />}
          {this.state.features.length > 0 && <div className="feature-container_items">
            {this.state.features && this.state.features.map(function(feature, index) {
              return <FeaturedNewsItem data={feature} key={index} />
            }, this)}
          </div>}
        </section>
      ); 
    } else {
      return null;
    }
  }
}
