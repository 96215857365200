import React from 'react';
// import heart from '../assets/images/heart.png'
import '../styles/loader.scss';

export default class Loader extends React.Component {
  hide() {
    if (this.props.show) {
      const elem = document.querySelector('html');
      if (elem) elem.classList.remove('ready');
      return 'loader';
    } else {
      const elem = document.querySelector('html');
      if (elem) elem.classList.add('ready');
      return 'loader hidden';
    }
  }

  render() {
    return (
      <div className={this.hide()}>
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
          <span>Loading</span>
        </div>
      </div>
    )
  }
}