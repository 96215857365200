import React from 'react';
import _ from 'lodash';

import { Heading3 } from './Headings';

import '../styles/filters.scss';

export default class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [],
      filters: [],
      selectedFilters: [],
    };
    this._setShow = function() {
      this.setState({
        show: this.show()
      });
    }.bind(this)
  }

  componentDidMount() {
    this.setFilters();
    window.addEventListener('scroll', this._setShow);
    this.height();
    this.setState({
      top: this.top()
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._setShow);
  }

  setFilters() {
    const { filters } = this.props;
    const modified = _.map(filters, (filter) => {
      filter.items = _.map(filter.items, (item) => {
        return {
          name: _.capitalize(item),
          value: item,
        }
      });

      return filter;
    });

    let selected = [];
    _.forEach(modified, (m) => {
      if (m.type === 'multi') {
        _.forEach(m.items, (item) => {
          selected.push({name: item.value, selected: true});
        })
      }
    });

    this.setState({
      filters: modified,
      selectedFilters: selected,
    });
  }

  filter(event) {

    let { callback, items, filteredItems } = this.props;
    let { selectedFilters } = this.state;
    const target = event.currentTarget;
    const type = target.value;
    const checked = _.isNil(target.checked) ? true : target.checked;
    const unique = _.isNil(target.checked);
    const parentType = target.getAttribute('data-type');
    const index = _.findIndex(selectedFilters, {name: type});

    if (unique && parentType) {
      const obj = {
        type: parentType,
        value: type,
      }
      const prev = _.findIndex(selectedFilters, {type: parentType});
      if (prev > -1) {
        selectedFilters[prev] = obj;
      } else {
        selectedFilters.push(obj);
      }
    } else {
      selectedFilters[index].selected = checked;
    }

    let currentFiltered = filteredItems;
    let all = items;
    
    all.forEach((item, index) => {
      const shownIndex = _.findIndex(currentFiltered, item);
      const shown = shownIndex > -1;
      let shouldShow = true;

      _.forEach(selectedFilters, (filter) => {
        if (_.isNil(filter.value)) {
          const inTags = item.tags.indexOf(filter.name) > -1;

          if (filter.selected && inTags) {
            shouldShow = true;
          } else if (!filter.selected && inTags) {
            shouldShow = false;
          }
        } else {
          shouldShow = filter.value.length ? _.get(item, `data.${filter.type}`) === filter.value : true;
        }

        if (!shouldShow) {
          return false;
        }
      });

      if (!shown && shouldShow) {
        currentFiltered.splice(index, 0, item);
      } else if (shown && !shouldShow) {
        currentFiltered.splice(shownIndex, 1);
      }
    });
    
    this.setState({
      selectedFilters,
    });

    if (_.isFunction(callback)) {
      callback(currentFiltered);
    }
  }

  show() {
    const nav = document.querySelector('.navigation');
    const secondary = document.querySelector('.secondary-nav_placeholder');
    const filter = document.querySelector('.filter-control');
    const placeholder = document.querySelector('.filter-control_placeholder');
    if (!filter || !placeholder) return;
    const topNav = secondary ? secondary : nav;
    const navHeight = topNav.offsetTop + topNav.offsetHeight;
    const top = placeholder.offsetTop;
    const passed = window.scrollY >= (top - navHeight);
    if (secondary && passed) document.querySelector('.secondary-nav').classList.add('hidden');
    if (secondary && !passed) document.querySelector('.secondary-nav').classList.remove('hidden');
    return passed;
  }

  top() {
    const nav = document.querySelector('.navigation');
    return nav.offsetTop + nav.offsetHeight + 'px';
  }

  height() {
    const self = this;
    setTimeout(() => {
      const filter = document.querySelector('.filter-control');
      if (!filter) return;
      self.setState({height: filter.offsetHeight});
    });
  }

  render() {
    const { filters } = this.state;
    return (
      <div className="grid-1 grid-container column padding-vertical-medium-l">
        <div
          className={this.show() ? "filter-control float" : "filter-control"}
          style={{top: this.show() ? this.state.top : 'auto'}}>

          <Heading3 text="Filters" />
          <div className="filters">
            {filters && filters.map(function(filter, index) {
              if (filter.type === 'unique' && filter.items.length > 0) {
                return <select className="filter-control_dropdown" data-type={filter.key} onChange={this.filter.bind(this)} key={index}>
                  {filter.default && <option value="">{filter.default}</option>}
                  {filter.items.map(function(f, i) {
                    return <option value={f.value} key={f.value}>{f.name}</option>
                  }, this)}
                </select>
              } else if (filter.type === 'multi') {
                return <div className="filter-control_select" key={index}>
                  {filter.items.map(function(f, i) {
                    return (
                      <label className="checkbox" key={f.value}>
                        <input type="checkbox" defaultChecked={true} value={f.value} onChange={this.filter.bind(this)} />{f.name}
                        <span></span>
                      </label>
                    )
                  }, this)}
                </div>
              } else {
                return <div />
              }
            }, this)}
          </div>
        </div>
        {filters && <div className="filter-control_placeholder" style={{height: this.state.height}}></div>}
      </div>
    )
  }
}